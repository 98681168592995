<template>
  <AdminNavbar>
    <h1 class="text-center font-bold text-xl">Мэдээ нэмэх</h1>

    <NewsEditor />
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/navigation/AdminNavbar.vue";
import NewsEditor from "@/containers/admin/NewsEditor.vue";

export default {
  name: "AdminAddNews",
  components: { NewsEditor, AdminNavbar },
};
</script>

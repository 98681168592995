<template>
  <main
    class="flex max-sm:flex-col gap-5 mt-5 mb-10 min-h-[60vh] max-[1199px]:mx-3"
  >
    <section v-if="!isLoading && education" class="w-full sm:w-2/3">
      <img :src="education.image" alt="education-image" class="w-full" />
      <h1 class="font-light text-2xl md:text-4xl my-5 text-center">
        {{ education.title }}
      </h1>
      <p v-html="education.text" class="text-sm"></p>
    </section>

    <section
      v-else
      class="flex justify-center items-center w-full sm:w-2/3 min-h-[50vh]"
    >
      <BarLoading />
    </section>

    <section class="w-full sm:w-1/3">
      <SocialFrame />
    </section>
  </main>
</template>

<script>
import SocialFrame from "@/containers/list/SocialFrame.vue";
import BarLoading from "@/components/UI/BarLoading.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import clientInstance from "@/lib/instance";
import { useRoute } from "vue-router";

export default {
  name: "EcologyEducationDetails",
  components: {
    SocialFrame,
    BarLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const route = useRoute();

    const isLoading = ref(false);
    const education = ref({});
    const fetchEducation = async (id) => {
      try {
        isLoading.value = true;
        const res = await clientInstance.post(
          "/EcologipublicationWeb/pubDetail",
          {
            ecologiId: id,
          }
        );
        if (res.status === 200) {
          education.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchEducation(route.params.id);
    });

    return { lang, education, isLoading };
  },
};
</script>

import { createStore } from "vuex";
import language from "./language";
import admin from "./admin";

export default createStore({
  namespaced: true,
  modules: {
    language,
    admin,
  },
});

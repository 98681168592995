<template>
  <CivicEducationContainer
    v-if="civicEducation.length > 0 && !isLoading"
    :civicEducation="civicEducation"
  />

  <div
    v-else-if="civicEducation.length === 0 && !isLoading"
    class="flex justify-center items-center min-h-[80vh]"
  >
    {{ lang === "mn" ? "Хуудас хоосон байна." : "No content is available." }}
  </div>

  <div v-else class="flex justify-center items-center min-h-[80vh]">
    <SpinLoading />
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import clientInstance from "@/lib/instance";
import { useStore } from "vuex";
import CivicEducationContainer from "@/containers/projects/CivicEducationContainer.vue";
import SpinLoading from "../../components/UI/SpinLoading";

export default {
  name: "CivicEducation",
  components: {
    CivicEducationContainer,
    SpinLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const type = "now";
    const isLoading = ref(false);
    const civicEducation = ref([]);

    const fetchCivicEducation = async () => {
      try {
        isLoading.value = true;
        const language = lang.value === "mn" ? 1 : 2;
        const res = await clientInstance.get(
          `/programsweb/get/civic-education?type=${type}&language=${language}`
        );
        if (res.status === 200) {
          civicEducation.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchCivicEducation();
    });

    watch(lang, async () => {
      await fetchCivicEducation();
    });

    return { isLoading, civicEducation };
  },
};
</script>

<template>
  <main class="flex justify-center items-center h-screen w-full bg-gray-100">
    <div class="bg-white shadow rounded-lg p-5 space-y-3">
      <h1 class="uppercase font-bold text-[#40a4c8] text-xl text-center">
        Админ
      </h1>
      <p v-if="unregistered" class="text-center text-red-500 text-xs mx-5">
        {{ unregistered }}
      </p>
      <div class="flex justify-center p-5">
        <div id="buttonDiv"></div>
      </div>
      <p class="text-center text-gray-500 text-xs mt-10">
        &copy; All rights reserved.
      </p>
    </div>
  </main>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "AdminLogin",
  setup() {
    const store = useStore();
    const error = ref("");
    const unregistered = ref(null);

    const handleCredentialResponse = async (response) => {
      try {
        const res = await store.dispatch("admin/fetchAdminInformation", {
          idToken: response.credential,
        });
        if (res && res.status === 200) {
          setTokenAndRedirect(res);
        } else if (res && res.status === 400) {
          unregistered.value =
            "Бүртгэлгүй и-мэйл хаяг байна. Админ хэрэглэгчид хандана уу!";
        } else {
          unregistered.value = "Алдаа гарлаа!";
        }
      } catch (e) {
        error.value = e.response.data.message;
      }
    };

    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id:
          "954068567646-hqnf0313o5sps9rfrtivlo3ddtd7aqrp.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }
      );
      window.google.accounts.id.prompt();
    };

    onMounted(() => {
      initializeGoogleSignIn();
    });

    const setTokenAndRedirect = (res) => {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 5 * 60 * 60 * 1000);
      Cookies.set("token", res.data.token, {
        expires: expirationDate,
      });
      if (res.data.token) {
        window.location.href = "/admin/dashboard";
      }
    };

    return {
      handleCredentialResponse,
      initializeGoogleSignIn,
      error,
      unregistered,
    };
  },
};
</script>

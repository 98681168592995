<template>
  <AdminNavbar>
    <h1 class="text-center font-bold text-xl">Слайд зургууд</h1>

    <main class="bg-white rounded-xl min-h-[80vh]">
      <section v-if="!editingMode">
        <div class="p-3 border-b">
          <button
            @click="setEditMode()"
            class="p-2 text-blue-500 text-xs rounded border border-blue-500 hover:bg-blue-600 hover:text-white"
          >
            <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />Слайд нэмэх
          </button>
        </div>
        <div v-if="sliders.length > 0 && !isLoading" class="p-3">
          <hot-table :settings="hotSettings" :data="sliders"></hot-table>
        </div>
      </section>

      <section v-else-if="editingMode">
        <EditSlider :slide="selectedSlide" @cancel-edit="cancelEditMode" />
      </section>
    </main>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/navigation/AdminNavbar.vue";
import EditSlider from "@/containers/admin/EditSlider.vue";
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "AdminHomeSlider",
  components: {
    AdminNavbar,
    EditSlider,
    HotTable,
  },
  setup() {
    const isLoading = ref(false);
    const sliders = ref([]);

    const fetchSliderImages = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/sliderweb/all");
        if (res.status === 200) sliders.value = res.data;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchSliderImages();
    });

    const editingMode = ref(false);
    const setEditMode = () => {
      editingMode.value = true;
    };
    const cancelEditMode = () => {
      editingMode.value = false;
      selectedSlide.value = null;
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const imageRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<div class="relative flex items-center justify-center p-2">
            <img src="${value}" alt="" class="object-fit" />
          </div>`;
    };

    const hotSettings = {
      colHeaders: ["Үйлдэл", "Слайд ID", "Зураг", "Гарчиг", "Тайлбар"],
      colWidths: [200, 120, 400, 200, 200],
      columns: [
        {
          renderer: function (inst, td, row) {
            const currentSlider = sliders.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                      <span>Засварлах</span>
                  </button>
                  <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                      <span>Устгах</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editSlider(currentSlider);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteSlider(currentSlider.id);
              });
            }
          },
          readOnly: true,
        },
        {
          data: "id",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "image",
          readOnly: true,
          renderer: imageRenderer,
        },
        {
          data: "title",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "text",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "70vh",
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const selectedSlide = ref(null);
    const editSlider = (slide) => {
      selectedSlide.value = slide;
      editingMode.value = true;
    };

    const deleteSlider = async (slideId) => {
      if (!window.confirm(`Слайдыг устгах уу?`)) {
        return;
      }

      try {
        const res = await clientInstance.put("/sliderweb/delete", {
          id: slideId,
        });
        if (res.status === 200) {
          await fetchSliderImages();
        }
      } catch (err) {
        return err;
      }
    };

    return {
      isLoading,
      sliders,
      editingMode,
      setEditMode,
      cancelEditMode,
      hotSettings,
      selectedSlide,
    };
  },
};
</script>

<template>
  <main class="bg-white rounded-xl min-h-[80vh] p-5">
    <div v-if="form.id" class="flex justify-end border-b pb-3 mb-5">
      <button
        @click="backToNewsList"
        class="py-1.5 px-3 text-primary rounded border border-primary hover:bg-primary hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" />
        Буцах
      </button>
    </div>

    <div class="flex items-center gap-5">
      <label for="language" class="w-[10rem] text-end"
        >Хэл:<span class="text-red-500">*</span></label
      >
      <select
        name="language"
        id="language"
        class="w-full p-2 border rounded"
        :class="!form.language && isSubmitted ? 'border-red-500' : ''"
        v-model="form.language"
      >
        <option value="1">Монгол хэл</option>
        <option value="2">Англи хэл</option>
      </select>
    </div>

    <div class="flex items-center gap-5 mt-5">
      <label for="title" class="w-[10rem] text-end"
        >Гарчиг:<span class="text-red-500">*</span></label
      >
      <input
        id="title"
        name="title"
        type="text"
        class="w-full p-2 border rounded"
        :class="!form.title && isSubmitted ? 'border-red-500' : ''"
        v-model="form.title"
      />
    </div>

    <div class="flex items-center gap-5 mt-5">
      <label class="w-[10rem] text-end">
        Нүүр зураг:<span class="text-red-500">*</span>
      </label>
      <div class="space-y-3 w-full">
        <input
          type="file"
          class="w-full p-2 border rounded"
          :class="!mainImage && isSubmitted ? 'border-red-500' : ''"
          @change="onImageChange"
          accept="image/jpeg, image/png"
        />
      </div>
    </div>

    <div class="flex gap-5">
      <span class="w-[10rem]"></span>
      <div class="w-full">
        <span class="text-sky-500 text-xs">
          Зөвхөн jpg, jpeg, png өргөтгөлтэй зураг оруулна уу.
        </span>
        <div
          class="flex flex-col justify-center items-center w-full min-h-[30vh] mt-3"
        >
          <img
            :src="imageSrc"
            alt="image"
            :class="!mainImage ? 'w-14 h-14' : 'w-3/4 object-contain'"
          />
          <span v-if="!mainImage" class="text-xs text-gray-500"
            >Зураг байхгүй байна</span
          >
        </div>
      </div>
    </div>

    <div class="flex gap-5 mt-5">
      <label for="text" class="w-[10rem] text-end mt-3"
        >Агуулга:<span class="text-red-500">*</span></label
      >
      <div class="prose" style="max-width: 900px">
        <ckeditor
          :editor="Editor"
          v-model="editorData"
          :config="editorConfig"
        />
      </div>
    </div>

    <div class="flex justify-center mt-5">
      <button
        @click="uploadNews()"
        class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2 hover:scale-105"
        :class="form.id ? 'bg-blue-500' : 'bg-[#28a745]'"
      >
        <font-awesome-icon
          :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-plus'"
        />
        {{ form.id ? "Мэдээ засах" : "Мэдээ нэмэх" }}
      </button>
    </div>
  </main>
</template>

<script>
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Ckeditor } from "@ckeditor/ckeditor5-vue";

export default {
  name: "NewsEditor",
  props: {
    news: Object,
  },
  components: {
    Ckeditor,
  },
  setup(props, { emit }) {
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        if (props.news.image) {
          imageSrc.value = props.news.image;
          mainImage.value = props.news.image;
        } else {
          imageSrc.value = defaultImage;
          mainImage.value = null;
        }
      }
    };

    const editorData = ref("");
    const form = ref({
      id: null,
      title: null,
      text: null,
      language: 1,
    });

    onMounted(async () => {
      try {
        if (props.news) {
          form.value.title = props.news.title;
          editorData.value = props.news.text;
          form.value.id = props.news.id;
          imageSrc.value = props.news.image;
          mainImage.value = props.news.image;
          form.value.language = props.news.language;
        }
      } catch (err) {
        console.error(err);
      }
    });

    const isSubmitted = ref(false);
    const validateForm = () => {
      return (
        form.value.title &&
        editorData.value &&
        mainImage.value &&
        form.value.language
      );
    };

    const uploadNews = async () => {
      isSubmitted.value = true;

      if (!validateForm()) return;

      const formData = new FormData();
      formData.append("file", mainImage.value);
      formData.append("title", form.value.title);
      formData.append("text", editorData.value);
      formData.append("language", form.value.language);

      try {
        let res;
        if (!form.value.id) {
          res = await clientInstance.post("/newsweb/postnews", formData);
        } else {
          formData.append("id", props.news.id);
          res = await clientInstance.post("/newsweb/putnews", formData);
        }

        if (res.status === 200) {
          isSubmitted.value = false;
          alert("Амжилттай шинэчлэгдлээ.");
          window.location.reload();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    const resetForm = () => {
      imageSrc.value = defaultImage;
      mainImage.value = "";
      (form.value.id = null),
        (form.value.title = null),
        (editorData.value = null),
        (form.value.language = 1);
    };

    const backToNewsList = () => {
      resetForm();
      emit("cancel-edit");
    };

    /* CKEDITOR */
    class MyUploadAdapter {
      constructor(loader) {
        this.loader = loader;
      }

      upload() {
        return this.loader.file.then(
          (file) =>
            new Promise((resolve, reject) => {
              this._initRequest();
              this._initListeners(resolve, reject, file);
              this._sendRequest(file);
            })
        );
      }

      abort() {
        if (this.xhr) {
          this.xhr.abort();
        }
      }

      _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open("POST", "https://cce.mn/newsweb/ckupload", true);
        xhr.responseType = "json";
      }

      _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
          const response = xhr.response;

          if (!response || response.error) {
            return reject(
              response && response.error
                ? response.error.message
                : genericErrorText
            );
          }

          console.log(response);
          resolve({
            default: response.url,
          });
        });

        if (xhr.upload) {
          xhr.upload.addEventListener("progress", (evt) => {
            if (evt.lengthComputable) {
              loader.uploadTotal = evt.total;
              loader.uploaded = evt.loaded;
            }
          });
        }
      }

      _sendRequest(file) {
        const data = new FormData();
        data.append("file", file);
        this.xhr.send(data);
      }
    }

    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    }

    const editorConfig = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      removePlugins: ["MediaEmbedToolbar", "Markdown"],
      alignment: {
        options: ["left", "right", "center", "justify"],
      },
      language: "en",
      licenseKey: "",
    };

    return {
      form,
      mainImage,
      imageSrc,
      onImageChange,
      uploadNews,
      isSubmitted,
      backToNewsList,
      editorConfig,
      Editor,
      editorData,
    };
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 700px;
}
</style>

<template>
  <div class="flex justify-center text-[#212529]">
    <h1
      class="text-center text-xl sm:text-2xl lg:text-3xl font-thin pt-8 p-3 border-b border-gray-500"
    >
      {{ lang === "mn" ? title.mn : title.en }}
    </h1>
  </div>

  <main
    class="flex gap-5 mt-5 mb-10 min-h-[60vh] max-[1199px]:px-3 overflow-hidden"
  >
    <section
      v-if="!isLoading && data.length > 0"
      class="w-full md:w-2/3 lg:w-3/4"
    >
      <div
        v-if="details"
        class="grid grid-cols-1 min-[500px]:grid-cols-2 lg:grid-cols-3 gap-5"
      >
        <DetailsCard
          v-for="item in paginatedData"
          :key="item.id"
          :data="item"
        />
      </div>

      <div
        v-else
        class="grid grid-cols-1 min-[500px]:grid-cols-2 lg:grid-cols-3 gap-5"
      >
        <FileCard v-for="item in paginatedData" :key="item.id" :item="item" />
      </div>
      <div class="flex justify-center mt-5">
        <button
          v-if="showLoadMore"
          @click="loadMore"
          class="border-2 border-[#40a4c8] px-5 py-2 text-[#212529] font-light"
        >
          {{ lang === "mn" ? "Цааш унших" : "Read more" }}
        </button>
      </div>
    </section>

    <section
      v-else-if="!isLoading && data.length === 0"
      class="flex justify-center items-center w-full md:w-2/3 lg:w-3/4 min-h-[50vh] uppercase text-xl text-gray-500"
    >
      {{ lang === "mn" ? "Хуудас хоосон байна" : "No data is available" }}
    </section>

    <section
      v-else
      class="flex justify-center items-center w-full md:w-2/3 lg:w-3/4 min-h-[50vh]"
    >
      <BarLoading />
    </section>

    <section class="hidden md:flex md:w-1/3 lg:w-1/4">
      <SocialFrame />
    </section>
  </main>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import DetailsCard from "./DetailsCard.vue";
import FileCard from "./FileCard.vue";
import SocialFrame from "./SocialFrame.vue";
import BarLoading from "@/components/UI/BarLoading.vue";

export default {
  name: "ListContainer",
  props: {
    title: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
    details: {
      type: Boolean,
    },
  },
  components: {
    DetailsCard,
    FileCard,
    SocialFrame,
    BarLoading,
  },
  setup(props) {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const itemsToShow = ref(9);

    const loadMore = () => {
      itemsToShow.value += 9;
    };

    const paginatedData = computed(() => {
      return props.data.slice(0, itemsToShow.value);
    });

    const showLoadMore = computed(() => {
      return itemsToShow.value < props.data.length;
    });

    return { lang, paginatedData, itemsToShow, loadMore, showLoadMore };
  },
};
</script>

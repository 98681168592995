import { createRouter, createWebHistory } from "vue-router";
// import Cookies from "js-cookie";

import HomePage from "./pages/HomePage.vue";
//about
import BoardMembers from "./pages/AboutUs/BoardMembers.vue";
import OurTeam from "./pages/AboutUs/OurTeam.vue";
import CompanyPartners from "./pages/AboutUs/CompanyPartners.vue";
import CompanyChronicles from "./pages/AboutUs/CompanyChronicles.vue";
//programs
import CivicEducation from "./pages/Projects/CivicEducation.vue";
import CivicParticipation from "./pages/Projects/CivicParticipation.vue";
import YouthDevelopment from "./pages/Projects/YouthDevelopment.vue";
import EcologyEducation from "./pages/Projects/EcologyEducation.vue";
import CivicEducationPast from "./pages/Projects/CivicEducationPast.vue";
import CivicParticipationPast from "./pages/Projects/CivicParticipationPast.vue";
//news
import NewsList from "./pages/News/NewsList.vue";
import NewsDetails from "./pages/News/NewsDetails.vue";
//archives
import ArchiveReports from "./pages/Archives/ArchiveReports.vue";
import SuccessStories from "./pages/Archives/SuccessStories.vue";
import BeneficiariesFeedback from "./pages/Archives/BeneficiariesFeedback.vue";
import DonorEvaluation from "./pages/Archives/DonorEvaluation.vue";
import ArchiveDetails from "./pages/Archives/ArchiveDetails.vue";
//publication
import CivicEducationPub from "./pages/Publications/CivicEducationPub.vue";
import CivicParticipationPub from "./pages/Publications/CivicParticipationPub.vue";
import YouthDevelopmentPub from "./pages/Publications/YouthDevelopmentPub.vue";
import EcologyEducationPub from "./pages/Publications/EcologyEducationPub.vue";
import CivicEducationDetails from "./pages/Publications/details/CivicEducationDetails.vue";
import CivicParticipationDetails from "./pages/Publications/details/CivicParticipationDetails.vue";
import YouthDevelopmentDetails from "./pages/Publications/details/YouthDevelopmentDetails.vue";
import EcologyEducationDetails from "./pages/Publications/details/EcologyEducationDetails.vue";

import NotFoundPage from "./pages/NotFoundPage.vue";

//admin
import AdminLogin from "./pages/Admin/AdminLogin.vue";
import AdminDashboard from "./pages/Admin/AdminDashboard.vue";
import AdminAddNews from "./pages/Admin/News/AdminAddNews.vue";
import AdminEditNews from "./pages/Admin/News/AdminEditNews.vue";
import AdminQuizQuestions from "./pages/Admin/Yvote/AdminQuizQuestions.vue";
import AdminCalendar from "./pages/Admin/Yvote/AdminCalendar.vue";
import AdminHomeSlider from "./pages/Admin/Home/AdminHomeSlider.vue";
import AdminBoardMembers from "./pages/Admin/AboutUs/AdminBoardMembers.vue";
import AdminOurTeam from "./pages/Admin/AboutUs/AdminOurTeam.vue";
import AdminChronicles from "./pages/Admin/AboutUs/AdminChronicles.vue";
import AdminPartners from "./pages/Admin/AboutUs/AdminPartners.vue";
import AdminArchiveReports from "./pages/Admin/Archives/AdminArchiveReports.vue";
import AdminArchiveStory from "./pages/Admin/Archives/AdminArchiveStory.vue";
import AdminArchiveFeedback from "./pages/Admin/Archives/AdminArchiveFeedback.vue";
import AdminArchiveEvaluation from "./pages/Admin/Archives/AdminArchiveEvaluation.vue";
import AdminCivicPub from "./pages/Admin/Publications/AdminCivicPub.vue";
import AdminParticipationPub from "./pages/Admin/Publications/AdminParticipationPub.vue";
import AdminYouthPub from "./pages/Admin/Publications/AdminYouthPub.vue";
import AdminEcologyPub from "./pages/Admin/Publications/AdminEcologyPub.vue";
import AdminCivicEducation from "./pages/Admin/Projects/AdminCivicEducation.vue";

const clientRoutes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/about/board-members",
    component: BoardMembers,
  },
  {
    path: "/about/our-team",
    component: OurTeam,
  },
  {
    path: "/about/partners",
    component: CompanyPartners,
  },
  {
    path: "/about/chronicles",
    component: CompanyChronicles,
  },
  {
    path: "/programs/civic-education",
    component: CivicEducation,
  },
  {
    path: "/programs/civic-participation",
    component: CivicParticipation,
  },
  {
    path: "/programs/youth-development",
    component: YouthDevelopment,
  },
  {
    path: "/programs/ecology-education",
    component: EcologyEducation,
  },
  {
    path: "/programs/civic-education-past",
    component: CivicEducationPast,
  },
  {
    path: "/programs/civic-participation-past",
    component: CivicParticipationPast,
  },
  {
    path: "/news",
    component: NewsList,
  },
  {
    path: "/archive/reports",
    component: ArchiveReports,
  },
  {
    path: "/archive/stories",
    component: SuccessStories,
  },
  {
    path: "/archive/beneficiary-feedback",
    component: BeneficiariesFeedback,
  },
  {
    path: "/archive/donor-evaluation",
    component: DonorEvaluation,
  },
  {
    path: "/publication/civic-education",
    component: CivicEducationPub,
  },
  {
    path: "/publication/civic-participation",
    component: CivicParticipationPub,
  },
  {
    path: "/publication/youth-development",
    component: YouthDevelopmentPub,
  },
  {
    path: "/publication/ecology-education",
    component: EcologyEducationPub,
  },
  {
    path: "/news/:id",
    component: NewsDetails,
  },
  {
    path: "/archive/:id",
    component: ArchiveDetails,
  },
  {
    path: "/publication/civic-education/:id",
    component: CivicEducationDetails,
  },
  {
    path: "/publication/civic-participation/:id",
    component: CivicParticipationDetails,
  },
  {
    path: "/publication/youth-development/:id",
    component: YouthDevelopmentDetails,
  },
  {
    path: "/publication/ecology-education/:id",
    component: EcologyEducationDetails,
  },
];

const adminRoutes = [
  {
    path: "/admin/login",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/add-news",
    component: AdminAddNews,
  },
  {
    path: "/admin/edit-news",
    component: AdminEditNews,
  },
  {
    path: "/admin/quiz",
    component: AdminQuizQuestions,
  },
  {
    path: "/admin/calendar",
    component: AdminCalendar,
  },
  {
    path: "/admin/slider",
    component: AdminHomeSlider,
  },
  {
    path: "/admin/board-members",
    component: AdminBoardMembers,
  },
  {
    path: "/admin/our-team",
    component: AdminOurTeam,
  },
  {
    path: "/admin/chronicles",
    component: AdminChronicles,
  },
  {
    path: "/admin/partners",
    component: AdminPartners,
  },
  {
    path: "/admin/archive/reports",
    component: AdminArchiveReports,
  },
  {
    path: "/admin/archive/stories",
    component: AdminArchiveStory,
  },
  {
    path: "/admin/archive/feedback",
    component: AdminArchiveFeedback,
  },
  {
    path: "/admin/archive/evaluation",
    component: AdminArchiveEvaluation,
  },
  {
    path: "/admin/pub/civic",
    component: AdminCivicPub,
  },
  {
    path: "/admin/pub/participation",
    component: AdminParticipationPub,
  },
  {
    path: "/admin/pub/youth",
    component: AdminYouthPub,
  },
  {
    path: "/admin/pub/ecology",
    component: AdminEcologyPub,
  },
  {
    path: "/admin/civic-education",
    component: AdminCivicEducation,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/:pathMatch(.*)*", name: "Page404", component: NotFoundPage },
    ...clientRoutes,
    ...adminRoutes,
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(() => {
  document.title = "CCE | Иргэний Боловсролын Төв";

  // const token = Cookies.get("token");

  // if (to.path.includes("admin")) {
  //   if (to.path === "/admin/login") {
  //     if (token) {
  //       return next("/admin/dashboard");
  //     } else {
  //       return next();
  //     }
  //   } else {
  //     if (token) {
  //       return next();
  //     } else {
  //       return next("/admin/login");
  //     }
  //   }
  // } else {
  //   return next();
  // }
});

export default router;

<template>
  <div class="flex justify-center bg-gray-100 w-full">
    <main v-if="!adminPage" class="w-full min-[1200px]:w-4/5">
      <MainNavbar />
      <RouterView />
      <MainFooter />
    </main>

    <main v-else>
      <RouterView />
    </main>
  </div>
</template>

<script>
import MainNavbar from "./components/navigation/MainNavbar.vue";
import MainFooter from "./components/navigation/MainFooter";
import { useRoute } from "vue-router";
import { computed } from "vue";

export default {
  name: "App",
  components: {
    MainNavbar,
    MainFooter,
  },
  setup() {
    const route = useRoute();

    const adminPage = computed(() => route.path.includes("/admin"));

    return {
      adminPage,
    };
  },
};
</script>

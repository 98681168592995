<template>
  <section>
    <div class="p-3 border-b">
      <button
        @click="cancelEditMode()"
        class="p-2 text-gray-500 text-xs rounded border border-gray-300 hover:bg-gray-400 hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />Буцах
      </button>
    </div>

    <div class="p-5">
      <div class="flex items-center gap-5 mt-5">
        <label for="date" class="w-[10rem] text-end"
          >Огноо:<span class="text-red-500">*</span></label
        >
        <input
          id="date"
          name="date"
          type="date"
          class="w-full p-2 border rounded"
          :class="!form.date && isSubmitted ? 'border-red-500' : ''"
          v-model="form.date"
        />
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="title" class="w-[10rem] text-end"
          >Тэмдэглэлт өдөр:<span class="text-red-500">*</span></label
        >
        <input
          id="title"
          name="title"
          type="text"
          placeholder="Тэмдэглэлт өдөр"
          class="w-full p-2 border rounded"
          :class="!form.title && isSubmitted ? 'border-red-500' : ''"
          v-model="form.title"
        />
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="text" class="w-[10rem] text-end"
          >Тэмдэглэл:<span class="text-red-500">*</span></label
        >
        <textarea
          name="text"
          id="text"
          class="w-full p-2 border rounded"
          rows="10"
          placeholder="Тэмдэглэл"
          :class="!form.text && isSubmitted ? 'border-red-500' : ''"
          v-model="form.text"
        ></textarea>
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="uploadAnniversary()"
          class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2"
          :class="
            form.Pid
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-[#28a745] hover:bg-[#208537]'
          "
        >
          <font-awesome-icon
            :icon="form.Pid ? 'fa-solid fa-pen' : 'fa-solid fa-plus'"
          />
          {{ form.Pid ? "Хадгалах" : "Нэмэх" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";
import { formatIOSDate } from "@/lib/formatDate";

export default {
  name: "EditCalendar",
  props: {
    anniversary: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const form = ref({
      Pid: null,
      date: null,
      title: null,
      text: null,
    });

    onMounted(() => {
      try {
        if (props.anniversary) {
          form.value.Pid = props.anniversary.Pid;
          form.value.date = formatIOSDate(props.anniversary.date);
          form.value.title = props.anniversary.title;
          form.value.text = props.anniversary.text;
        }
      } catch (err) {
        console.error(err);
      }
    });

    const isSubmitted = ref(false);
    const validateForm = () => {
      return form.value.date;
    };

    const uploadAnniversary = async () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      try {
        let res;
        if (form.value.Pid) {
          res = await clientInstance.post("/calendarweb/update", form.value);
        } else {
          res = await clientInstance.post("/calendarweb/insert", form.value);
        }

        if (res.status === 200) {
          isSubmitted.value = false;
          alert("Амжилттай шинэчлэгдлээ.");
          window.location.reload();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    const resetForm = () => {
      form.value = {
        Pid: null,
        date: null,
        title: null,
        text: null,
      };
    };

    const cancelEditMode = () => {
      resetForm();
      emit("cancel-edit");
    };

    return {
      form,
      isSubmitted,
      uploadAnniversary,
      cancelEditMode,
    };
  },
};
</script>

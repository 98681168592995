<template>
  <ListContainer
    :title="title"
    :data="news"
    :details="true"
    :isLoading="isLoading"
  />
</template>

<script>
import clientInstance from "@/lib/instance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import ListContainer from "@/containers/list/ListContainer.vue";

export default {
  name: "NewsList",
  components: {
    ListContainer,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const title = {
      mn: "Мэдээ",
      en: "News",
    };
    const news = ref([]);
    const isLoading = ref(false);

    const fetchNews = async () => {
      try {
        isLoading.value = true;
        const language = lang.value === "mn" ? 1 : 2;
        const res = await clientInstance.post("/newsweb/newslanguage", {
          language: language,
        });
        if (res.status === 200) {
          news.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchNews();
    });

    watch(lang, async () => {
      await fetchNews();
    });

    return {
      lang,
      title,
      news,
      isLoading,
    };
  },
};
</script>

<template>
  <div
    v-for="(participation, index) in civicParticipation"
    :key="index"
    class="mb-10"
  >
    <div class="flex justify-center text-[#38b7e5] max-[1199px]:mx-3">
      <h1
        class="text-center text-base sm:text-xl lg:text-2xl pt-8 p-3 border-b-2 border-[#38b7e5]"
      >
        {{ lang === "mn" ? participation.title : participation.title_en }}
      </h1>
    </div>

    <div class="flex flex-col items-center gap-5 max-sm:px-3">
      <div
        v-if="lang === 'mn'"
        class="bg-[#CDEFFC] w-full sm:w-2/3 md:w-1/2 p-5 my-5"
      >
        <p
          v-for="(sentence, sIndex) in participation.sentences"
          :key="sIndex"
          class="border-b-4 border-white p-3 text-sm font-light"
        >
          {{ sentence }}
        </p>
      </div>

      <div v-else class="bg-[#CDEFFC] w-full sm:w-2/3 md:w-1/2 p-5 my-5">
        <p
          v-for="(sentence, sIndex) in participation.sentences_en"
          :key="sIndex"
          class="border-b-4 border-white p-3 text-sm font-light"
        >
          {{ sentence }}
        </p>
      </div>

      <div
        v-if="participation.description || participation.description_en"
        class="w-full sm:w-2/3 md:w-1/2 p-8 rounded-xl bg-[#CDEFFC] border border-[#1286ac] text-center text-sm font-light"
      >
        {{
          lang === "mn"
            ? participation.description
            : participation.description_en
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "CivicParticipationContainer",
  props: {
    civicParticipation: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>

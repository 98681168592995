<template>
  <PublicationContainer
    :title="title"
    :data="development"
    :isLoading="isLoading"
  />
</template>

<script>
import PublicationContainer from "@/containers/publications/PublicationContainer.vue";
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";

export default {
  name: "YouthDevelopmentPub",
  components: {
    PublicationContainer,
  },
  setup() {
    const title = {
      mn: "Залуучуудын хөгжил ном",
      en: "Youth development publication",
      color: "#ead904",
    };
    const isLoading = ref(false);
    const development = ref([]);

    const fetchDevelopmentPub = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/youthWeb/youthdevelopment");
        if (res.status === 200) {
          development.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchDevelopmentPub();
    });

    return {
      title,
      isLoading,
      development,
    };
  },
};
</script>

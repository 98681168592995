<template>
  <footer class="bg-[#212529] p-5 sm:p-10 text-white">
    <h4 class="text-xl border-b-4 border-[#40a4c8] pb-3">Холбоо барих</h4>
    <main
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 font-thin mt-3"
    >
      <p>
        {{
          lang === "mn"
            ? "Монгол улс, Улаанбаатар хот, Сүхбаатар дүүрэг, Авто замчдын гудамж, Хасвуу плаза 1-р давхар Иргэний Боловсролын Төв"
            : "Center for Citizenship Education, 1st floor Khasvuu Plaza, AvtoZamchdiin Street, Sukhbaatar District, Ulaanbaatar, Mongolia"
        }}
      </p>

      <ul>
        <li>
          {{
            lang === "mn"
              ? "Пост хаяг: Улаанбаатар 11"
              : "Post Address: Ulaanbaatar 11, Mongolia"
          }}
        </li>
        <li>
          {{
            lang === "mn" ? "Утас: 976-11-313619" : "Phone/Fax:976-11-313619"
          }}
        </li>
        <li>
          {{ lang === "mn" ? "Имэйл:" : "Email:" }} naraa_r@yahoo.com,
          ariuna.cce.mn@gmail.com
        </li>
      </ul>

      <section class="space-y-5">
        <div class="flex justify-center">
          <button
            @click="selectLanguage('mn')"
            class="pr-2 border-r border-white"
            :class="lang === 'mn' ? 'text-[#40a4c8]' : 'text-white'"
          >
            Монгол
          </button>
          <button
            @click="selectLanguage('en')"
            class="pl-2"
            :class="lang === 'en' ? 'text-[#40a4c8]' : 'text-white'"
          >
            English
          </button>
        </div>
        <div class="flex justify-center gap-3">
          <a
            href="https://www.facebook.com/cce.mongolia"
            target="_blank"
            class="flex justify-center items-center bg-[#3b5998] h-10 w-10 rounded-full hover:scale-105"
          >
            <font-awesome-icon icon="fa-brands fa-facebook-f" />
          </a>
          <a
            href="https://twitter.com/cce_mongolia"
            target="_blank"
            class="flex justify-center items-center bg-white h-10 w-10 rounded-full hover:scale-105"
          >
            <font-awesome-icon
              icon="fa-brands fa-x-twitter"
              class="text-black"
            />
          </a>
        </div>
      </section>

      <section class="flex justify-center items-center gap-5 text-white">
        <div class="font-bold text-center text-primary">
          <img
            src="../../assets/images/logo/yvote.png"
            alt="app"
            class="rounded-lg w-14 object-fit"
          />
          Yvote
        </div>
        <div class="text-xs uppercase font-bold space-y-3">
          <a
            href="https://apps.apple.com/mn/app/yvote/id1514128502?uo=2"
            target="_blank"
            class="flex items-center gap-2 w-[8.5rem] border rounded py-1.5 px-3 hover:scale-105"
          >
            <img
              src="../../assets/images/logo/app-store.png"
              alt="app-store"
              class="w-7 object-contain"
            />
            App store
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=mn.bowsys.yvote&hl=en_US"
            target="_blank"
            class="flex items-center gap-2 w-[8.5rem] border rounded py-1.5 px-3 hover:scale-105"
          >
            <img
              src="../../assets/images/logo/google-play.png"
              alt="app-store"
              class="w-6 object-contain"
            />
            Play store
          </a>
        </div>
      </section>
    </main>

    <div class="flex justify-start items-center w-full mt-8 text-xs">
      <a href="https://bowsys.mn" target="_self" class="hover:underline"
        >&copy; Developed By BOW LLC</a
      >
    </div>
  </footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MainFooter",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const selectLanguage = (value) => {
      store.dispatch("toggleLanguage", value);
    };

    return {
      lang,
      selectLanguage,
    };
  },
};
</script>

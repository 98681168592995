<template>
  <main class="flex flex-col items-center w-full">
    <div v-for="(edu, index) in ecology" :key="index" class="w-full sm:w-4/5">
      <div class="flex justify-center text-[#1aa65d] max-[1199px]:mx-3">
        <h1
          class="font-bold text-center text-lg sm:text-2xl lg:text-3xl pt-8 p-3 border-b-2 border-[#1aa65d]"
        >
          {{ lang === "mn" ? edu.title : edu.title_en }}
        </h1>
      </div>

      <div class="flex max-sm:flex-col gap-5 my-5 max-sm:px-3">
        <div
          v-if="edu.text1_en || edu.text1"
          class="flex flex-col items-center bg-[#d4f7e4] gap-3 rounded-lg p-3 text-center text-sm font-light"
        >
          <div
            class="flex justify-center items-center bg-[#93f1a6] w-12 h-12 rounded-xl"
          >
            <font-awesome-icon
              icon="fa-solid fa-building-columns"
              class="text-white text-2xl"
            />
          </div>
          {{ lang === "mn" ? edu.text1 : edu.text1_en }}
        </div>
        <div
          v-if="edu.text2_en || edu.text2"
          class="flex flex-col items-center bg-[#d4f7e4] gap-3 rounded-lg p-3 text-center text-sm font-light"
        >
          <div
            class="flex justify-center items-center bg-[#93f1a6] w-12 h-12 rounded-xl"
          >
            <font-awesome-icon
              icon="fa-solid fa-book"
              class="text-white text-2xl"
            />
          </div>
          {{ lang === "mn" ? edu.text2 : edu.text2_en }}
        </div>
        <div
          v-if="edu.text3_en || edu.text3"
          class="flex flex-col items-center bg-[#d4f7e4] gap-3 rounded-lg p-3 text-center text-sm font-light"
        >
          <div
            class="flex justify-center items-center bg-[#93f1a6] w-12 h-12 rounded-xl"
          >
            <font-awesome-icon
              icon="fa-solid fa-chalkboard-user"
              class="text-white text-2xl"
            />
          </div>
          {{ lang === "mn" ? edu.text3 : edu.text3_en }}
        </div>
      </div>
      <div class="flex max-sm:flex-col gap-5 my-5 max-sm:px-3">
        <div
          v-if="edu.text4_en || edu.text4"
          class="flex flex-col items-center bg-[#d4f7e4] gap-3 rounded-lg p-3 text-center text-sm font-light"
        >
          <div
            class="flex justify-center items-center bg-[#93f1a6] w-12 h-12 rounded-xl"
          >
            <font-awesome-icon
              icon="fa-solid fa-house-chimney-window"
              class="text-white text-2xl"
            />
          </div>
          {{ lang === "mn" ? edu.text4 : edu.text4_en }}
        </div>
        <div
          v-if="edu.text5_en || edu.text5"
          class="flex flex-col items-center bg-[#d4f7e4] gap-3 rounded-lg p-3 text-center text-sm font-light"
        >
          <div
            class="flex justify-center items-center bg-[#93f1a6] w-12 h-12 rounded-xl"
          >
            <font-awesome-icon
              icon="fa-solid fa-person"
              class="text-white text-3xl"
            />
          </div>
          {{ lang === "mn" ? edu.text5 : edu.text5_en }}
        </div>
        <div
          v-if="edu.text6_en || edu.text6"
          class="flex flex-col items-center bg-[#d4f7e4] gap-3 rounded-lg p-3 text-center text-sm font-light"
        >
          <div
            class="flex justify-center items-center bg-[#93f1a6] w-12 h-12 rounded-xl"
          >
            <font-awesome-icon
              icon="fa-solid fa-handshake-simple"
              class="text-white text-2xl"
            />
          </div>
          {{ lang === "mn" ? edu.text6 : edu.text6_en }}
        </div>
        <div
          v-if="edu.text7_en || edu.text7"
          class="flex flex-col items-center bg-[#d4f7e4] gap-3 rounded-lg p-3 text-center text-sm font-light"
        >
          <div
            class="flex justify-center items-center bg-[#93f1a6] w-12 h-12 rounded-xl"
          >
            <font-awesome-icon
              icon="fa-solid fa-earth-asia"
              class="text-white text-2xl"
            />
          </div>
          {{ lang === "mn" ? edu.text7 : edu.text7_en }}
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "EcologyEducation",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const ecology = ref([
      {
        title: "Экологийн боловсролын хөтөлбөр",
        title_en: "Ecological Education Program",
        text1:
          "Экологийн боловсролын хөтөлбөр орчуулан боловсруулж Монгол улсын боловсролын системд нэвтрүүлэх",
        text1_en:
          "Develop ecological education curriculum for Eco schools and camps and introduce into the Mongolian education system",
        text2:
          "Экологийн боловсролын ном сурах бичиг,гарын авлага боловсруулан хэвлэн нийтлэх,түгээх",
        text2_en:
          "Develop, publish, and disseminate ecological educational materials, textbooks and manuals",
        text3:
          "ЕБС - ийн багш нар болон байгаль орчны мэргэжилтнүүдэд сургалт явуулах",
        text3_en: "Train school teachers and environmentalists",
        text4:
          "Орон нутгийн иргэд болон байгаль орчны бүлгийн хүмүүст сургалт явуулах",
        text4_en:
          "Conduct training for local people and community environmental groups",
        text5: "Хүүхдүүдэд зориулсан экологийн боловсролын зуслан ажилуулах",
        text5_en:
          "Run an ecological summer camp for children and provide children with environmental education",
        text6:
          "Орон нутгийн иргэдийн байгаль орчинг хамгаалах санаачлага үйл ажиллагааг дэмжих",
        text6_en:
          "Support and promote environmental projection actions and initiatives of comminity",
        text7:
          "Байгаль орчны олон улсын солилцооны хөтөлбөрийг зохион байгуулах",
        text7_en: "Coordinate international environmental exchange programs",
      },
      {
        title: "Экологийн Боловсрол Олгох К-8 хөтөлбөр ( 2011 оноос )",
        title_en:
          "Environmental Education Program K-8 Ecological Education Program (since 2011)",
        text4:
          "Төслийн зорилго: Эко сургууль, зусланд зориулсан экологийн боловсролын хөтөлбөрийг Монгол улсад нэвтрүүлэх нь",
        text4_en:
          "Project goal: To introduce ecological education program for eco-schools and summer camps in Mongolia",
        text5:
          "Төслийн дэмжигч, хамтрагчид: АНУ-ын Ойн Газар, Байгаль Орчин Ногоон Хөгжлийн Яам, Байгаль хамгаалах сан, БСШУЯ",
        text5_en:
          "Project Supporters and Partners: US Department of Forestry, Department of Environment and Green Development, Environmental Protection Fund, Ministry of Education, Culture, Science and Sport.",
        text6:
          "Хөтөлбөрийн хүрээнд экологийн боловсролын сургалтыг ЕБС-ийн газар зүй, биологи, эко клубын багш нар болон байгалийн тусгай хамгаалалттай газрын байгаль хамгаалагч, төрийн бус байгууллагын ажилтнууд, зуслангийн багш нарт сургалт явуулдаг. Байгаль Орчин Ногоон Хөгжлийн Яам, Байгал Хамгаалах Сангийн дэмжлэгээр Экологийн боловсролын К-8 хөтөлбөрийг орчуулан боловсруулж “Сонирхолтой эко хичээлүүд” гарын авлага, “Эко сургууль, зусланд зориулсан гарын авлага” боловсруулсан хэвлэсэн хөтөлбөр гарын авлагатай.",
        text6_en:
          "3. The program provides ecological education to secondary school teachers of geography, biology, and eco-clubs, as well as to rangers, NGO staff, and summer camp teachers. With the support of the Ministry of Nature, Environment and Green Development and the Nature Conservation Fund, we have translated the K-8 program for ecological education and published a handbook on “Interesting eco-lessons” and a handbook for “Eco-schools and summer camps”. Project impact: The project has a training manual and curriculum. In total, more than 210 secondary school teachers and about 100 environmental and NGO activists were trained and provided with textbooks.",
        text7:
          "Төслийн үр нөлөө: Төслийн хүрээнд сургалтын гарын авлага, хөтөлбөртэй болсон. Нийтдээ ЕБС-ийн 210 гаруй багш, 100 шахам байгаль орчны болон ТББ-ын идэвхтнүүд сургалтад хамрагдаж хичээлийн арга зүйд суралцан ном сурах бичгээр хангагдсан.",
        text7_en:
          "Project impact: The project has a training manual and curriculum. In total, more than 210 secondary school teachers and about 100 environmental and NGO activists were trained and provided with textbooks.",
      },
      {
        title: "Эколэнд зуслан (2012 оноос)",
        title_en: "Ecoland summer camp (since 2012)",
        text4:
          "Төслийн зорилго: Орон нутгийн иргэд, ялангуяа хүүхэд залуучуудад орон нутгийнхаа байгаль хамгааллын асуудлыг өөрсдөө судлан мэдэж, тулгамдсан асуудлыг шийдвэрлэх, төрийн бодлогод нөлөөлөх арга барил эзэмшүүлж, цаашид бие даан тогтвортой үйл ажиллагаа явуулж орон нутгийнхаа эзэд байх мэдлэг, чадвартай болгох",
        text4_en:
          "Project objectives: To teach local people, especially children and youth, to study local environmental issues on their own, to solve problems, to learn how to influence public policy, and to have the knowledge and skills to be independent owners in the future.",
        text5:
          "Дэмжигчид: Японы Жайка Олон улсын байгууллага, АНУ-ын Ойн Газар, Ерөө сумын Засаг даргын тамгын газар, Иргэдийн Төлөөлөгчдийн Хурал, Ерөө сумын ЕБС, сайн дурын ажилтнууд",
        text5_en:
          "Supporters: Japan International Cooperation Agency (JICA), US Department of Forestry, Yoroo soum governor's office, Citizens' Representative Meeting, Yeroo soum secondary school, volunteers",
        text6:
          "Сүүлийн 8 жилийн турш манай байгууллага “ЭКОЛОГИЙН БОЛОВСРОЛООР ЗЭВСЭГЛЭН ЭХ НУТГАА ХАМГААЛЪЯ” төсөл Сэлэнгэ аймгийн Ерөө суманд хэрэгжүүлж байна. Сэлэнгэ аймгийн Ерөө сум нь манай орны хойд бүсэд оршдог, нийт нутаг дэвсгэрийнх нь 70 гаруй хувийг ой эзэлдэг. Зэрлэг ан амьтан, шувуу, шавьж хорхой, төрөл бүрийн мод, жимс, ургамалаар баялаг тансаг сайхан байгальтай. Сүүлийн жилүүдэд ой, зэрлэг амьтан, ургамал судлах, хамгаалах нэгдсэн арга хэмжээ явагдаагүй, харин байгалийн баялаг ашиглах үйл ажиллагаа хэт эрчимтэй явагдсан тул байгаль орчин ихээр сүйтгэгдсэн. Иймээс Ерөө сумын Баянгол хэмээх байгалийн үзэсгэлэнт газарт Экологийн боловсролын зуны зуслан байгуулж зорилготой ажиллаж байна. Манай зусланд Сэлэнгэ аймгийн Ерөө, Бугант, Жавхлант, Хүдэр зэрэг сумд болон Улаанбаатар, Дархан хотоос хүүхдүүдээс гадна АНУ, ОХУ-аас хүүхдүүд амрахаар ирдэг. Нэг ээлжиндээ 45 хүртэл хүүхэд амрах багтаамжтай.",
        text6_en:
          "For the last 8 years, our organization has been implementing “LET'S PROTECT OUR LAND GAINING ECOLOGICAL EDUCATION” project in Selenge aimag Yoroo soum. Yoroo soum is located in the northern region of our country and forests cover more than 70% of its territory. It is rich in wildlife, birds, insects, various trees, fruits and plants. In recent years, there is no comprehensive measures have been taken to study and protect forests, wildlife and plants, but the environment has been seriously damaged due to over-exploitation of natural resources. Therefore, we established a summer camp for ecological education in a beautiful natural place called Bayangol in Yeruu soum. In addition to children from Yoroo, Bugant, Javkhlant and Khuder soums, Ulaanbaatar and Darkhan, as well as from the United States and Russian children come to our summer camp. It can accommodate up to 45 children per shift.",
        text7:
          "300 гаруй хүүхэд залуус зусланд амарч, экологийн болон ардчиллын боловсролын суурь тавигдсан. Орон нутгийн 100 гаруй иргэд усны чанар шалгах тест хийж сурсан. Зусланд зориулсан сургалтын хөтөлбөртэй болсон.",
        text7_en:
          "Foundation of ecological and democratic education was provided to more than 300 children and young people in summer camp. More than 100 local people have learned to test water quality. We have a training program for summer camps.",
      },
    ]);

    return { lang, ecology };
  },
};
</script>

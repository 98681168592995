<template>
  <main
    class="flex max-sm:flex-col gap-5 mt-5 mb-10 min-h-[60vh] max-[1199px]:mx-3"
  >
    <section v-if="!isLoading && development" class="w-full sm:w-2/3">
      <img :src="development.image" alt="development-image" class="w-full" />
      <h1 class="font-light text-2xl md:text-4xl my-5 text-center">
        {{ development.title }}
      </h1>
      <p v-html="development.text" class="text-sm"></p>
    </section>

    <section
      v-else
      class="flex justify-center items-center w-full sm:w-2/3 min-h-[50vh]"
    >
      <BarLoading />
    </section>

    <section class="w-full sm:w-1/3">
      <SocialFrame />
    </section>
  </main>
</template>

<script>
import SocialFrame from "@/containers/list/SocialFrame.vue";
import BarLoading from "@/components/UI/BarLoading.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import clientInstance from "@/lib/instance";
import { useRoute } from "vue-router";

export default {
  name: "YouthDevelopmentDetails",
  components: {
    SocialFrame,
    BarLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const route = useRoute();

    const isLoading = ref(false);
    const development = ref({});
    const fetchDevelopment = async (id) => {
      try {
        isLoading.value = true;
        const res = await clientInstance.post("/youthWeb/pubDetail", {
          youthId: id,
        });
        if (res.status === 200) {
          development.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchDevelopment(route.params.id);
    });

    return { lang, development, isLoading };
  },
};
</script>

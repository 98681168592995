<template>
  <AdminNavbar>
    <h1 class="text-center font-bold text-xl">Мэдээ засварлах</h1>

    <main
      v-if="!isEditing"
      class="bg-white rounded-xl min-h-[80vh] p-5 space-y-5"
    >
      <section class="flex justify-between items-center text-xs">
        <div class="space-x-3">
          <span>Хэл:</span>
          <select
            name="selectedLanguage"
            id="selectedLanguage"
            class="border rounded p-1 text-blue-500 max-w-[20rem]"
            v-model="selectedLanguage"
          >
            <option value="all">Бүгд</option>
            <option value="1">Монгол хэл</option>
            <option value="2">Англи хэл</option>
          </select>
        </div>

        <div class="space-x-3">
          <span>Хуудас бүрт:</span>
          <select
            name="pagePerPage"
            id="pagePerPage"
            class="border rounded p-1 text-blue-500"
            v-model="dataPerPage"
          >
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="60">60</option>
          </select>
        </div>

        <span>Нийт мэдээний тоо: {{ totalNewsCount }} </span>

        <div class="flex items-center gap-3">
          <span>Нийт хуудас: {{ totalPages }} </span>
          <PaginationCart
            :totalPages="totalPages"
            :modelValue="currentPage"
            :style="{
              color: 'blue-500',
              height: 7,
              width: 7,
              fontSize: 'sm',
            }"
            @page-changed="onPageChanged"
          />
        </div>
      </section>

      <section class="">
        <div v-if="news.length > 0 && !isLoading">
          <hot-table :settings="hotSettings" :data="news"></hot-table>
        </div>

        <div
          v-else-if="news.length === 0 && !isLoading"
          class="flex justify-center items-center h-[60vh]"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center h-[60vh]">
          <SpinLoading />
        </div>
      </section>
    </main>

    <NewsEditor v-else :news="selectedNews" @cancel-edit="cancelEditMode" />
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/navigation/AdminNavbar.vue";
import NewsEditor from "@/containers/admin/NewsEditor.vue";
import PaginationCart from "@/components/UI/PaginationCart.vue";
import SpinLoading from "@/components/UI/SpinLoading.vue";
import { onMounted, ref, watch } from "vue";
import clientInstance from "@/lib/instance";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";
import { formatDate } from "@/lib/formatDate";

registerAllModules();

export default {
  name: "AdminEditNews",
  components: {
    AdminNavbar,
    NewsEditor,
    PaginationCart,
    HotTable,
    SpinLoading,
  },
  setup() {
    const isEditing = ref(false);
    const toggleEditMode = () => {
      isEditing.value = !isEditing.value;
    };

    const dataPerPage = ref(20);
    const totalNewsCount = ref(0);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const selectedLanguage = ref("all");
    const selectedNews = ref(null);
    const isLoading = ref(false);
    const news = ref([]);

    const fetchNews = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          `/newsweb/newsByLanguage?language=${selectedLanguage.value}&page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        if (res.status === 200) {
          news.value = res.data.news;
          totalPages.value = res.data.totalPages;
          totalNewsCount.value = res.data.total;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchNews();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchNews();
    };

    watch([() => dataPerPage.value, () => selectedLanguage.value], () => {
      currentPage.value = 1;
      fetchNews();
    });

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: ["Үйлдэл", "Мэдээний ID", "Гарчиг", "Нийтлэсэн огноо", "Хэл"],
      colWidths: [200, 120, 400, 150, 150],
      columns: [
        {
          renderer: function (inst, td, row) {
            const currentNews = news.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                      <span>Засварлах</span>
                  </button>
                  <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                      <span>Устгах</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editNews(currentNews);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteNews(currentNews.id);
              });
            }
          },
          readOnly: true,
        },
        {
          data: "id",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "title",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "date",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            const formattedDate = formatDate(value);
            td.classList.add("htMiddle");
            td.innerHTML = `<span class="flex justify-center p-2 text-black">${formattedDate}</span>`;
            return td;
          },
        },
        {
          data: "language",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htMiddle");
            td.innerHTML = `<span class="flex justify-center p-2 text-black">${
              value === 1 ? "Монгол" : "Англи"
            }</span>`;
            return td;
          },
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "70vh",
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const editNews = (currentNews) => {
      if (currentNews) {
        selectedNews.value = currentNews;
        isEditing.value = true;
      }
    };

    const deleteNews = async (newsId) => {
      if (!window.confirm(`${newsId} дугаартай мэдээг устгах уу?`)) {
        return;
      }

      try {
        isLoading.value = true;
        const res = await clientInstance.put("/newsweb/deletenews", {
          id: newsId,
        });
        if (res.status === 200) {
          await fetchNews();
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    const cancelEditMode = () => {
      selectedNews.value = null;
      isEditing.value = false;
    };

    return {
      isEditing,
      toggleEditMode,
      selectedLanguage,
      dataPerPage,
      totalNewsCount,
      totalPages,
      currentPage,
      onPageChanged,
      isLoading,
      news,
      hotSettings,
      selectedNews,
      cancelEditMode,
    };
  },
};
</script>

<template>
  <div v-for="(education, index) in civicEducation" :key="index" class="mb-10">
    <div class="flex justify-center text-[#ff0000]">
      <h1
        v-if="education.title"
        class="text-center text-base sm:text-xl lg:text-2xl pt-8 p-3 max-[1199px]:mx-3 border-b-2 border-[#ff0000]"
      >
        {{ education.title }}
      </h1>
    </div>

    <div class="flex flex-col items-center gap-5 my-5 w-full max-sm:px-3">
      <!-- p1 -->
      <div
        v-if="education.goal"
        class="flex items-center gap-5 w-full sm:w-2/3 md:w-1/2"
      >
        <font-awesome-icon
          icon="fa-solid fa-book-open"
          class="w-9 font-bold text-[#ff0000] text-2xl"
        />
        <p
          class="bg-[#F2D2BD] p-3 rounded-xl border border-[#fef0e3] font-light"
        >
          {{ education.goal }}
        </p>
      </div>
      <!-- p2 -->
      <div
        v-if="education.partner"
        class="flex items-center gap-5 sm:w-2/3 md:w-1/2"
      >
        <font-awesome-icon
          icon="fa-solid fa-person-chalkboard"
          class="w-8 font-bold text-[#ff0000] text-2xl"
        />
        <p
          class="bg-[#F2D2BD] p-3 rounded-xl border border-[#fef0e3] font-light w-full"
        >
          {{ education.partner }}
        </p>
      </div>
      <!-- p3 -->
      <div
        v-if="education.activity"
        class="flex items-center gap-5 sm:w-2/3 md:w-1/2"
      >
        <font-awesome-icon
          icon="fa-solid fa-user"
          class="w-10 font-bold text-[#ff0000] text-2xl"
        />
        <p
          class="bg-[#F2D2BD] p-3 rounded-xl border border-[#fef0e3] font-light"
        >
          {{ education.activity }}
        </p>
      </div>
      <!-- p4 -->
      <div
        v-if="education.effect"
        class="flex items-center gap-5 sm:w-2/3 md:w-1/2"
      >
        <font-awesome-icon
          icon="fa-solid fa-user-check"
          class="w-8 font-bold text-[#ff0000] text-2xl"
        />
        <p
          class="bg-[#F2D2BD] p-3 rounded-xl border border-[#fef0e3] font-light"
        >
          {{ education.effect }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "CivicEducationContainer",
  props: {
    civicEducation: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>

<template>
  <div :style="{ color: title.color }" class="flex justify-center">
    <h1
      :style="{ borderBottom: `1px solid ${title.color}` }"
      class="text-center text-xl sm:text-2xl lg:text-3xl font-light pt-8 p-3 border-b"
    >
      {{ lang === "mn" ? title.mn : title.en }}
    </h1>
  </div>

  <section v-if="!isLoading && data.length > 0">
    <div
      class="grid grid-cols-1 min-[400px]:grid-cols-2 min-[650px]:grid-cols-3 min-[900px]:grid-cols-4 gap-5 my-10 max-[1199px]:px-3"
    >
      <DetailsCard v-for="item in paginatedData" :key="item.id" :data="item" />
    </div>
    <div class="flex justify-center mb-10">
      <button
        v-if="showLoadMore"
        @click="loadMore"
        class="border-2 border-[#40a4c8] px-5 py-2 text-[#212529] font-light"
      >
        {{ lang === "mn" ? "Цааш унших" : "Read more" }}
      </button>
    </div>
  </section>

  <section
    v-else-if="!isLoading && data.length === 0"
    class="flex justify-center items-center w-full min-h-[50vh] uppercase text-xl text-gray-500"
  >
    {{ lang === "mn" ? "Хуудас хоосон байна" : "No data is available" }}
  </section>

  <section v-else class="flex justify-center items-center w-full min-h-[50vh]">
    <BarLoading />
  </section>
</template>

<script>
import DetailsCard from "@/containers/list/DetailsCard.vue";
import BarLoading from "@/components/UI/BarLoading.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "PublicationContainer",
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    DetailsCard,
    BarLoading,
  },
  setup(props) {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const itemsToShow = ref(8);

    const loadMore = () => {
      itemsToShow.value += 8;
    };

    const paginatedData = computed(() => {
      return props.data.slice(0, itemsToShow.value);
    });

    const showLoadMore = computed(() => {
      return itemsToShow.value < props.data.length;
    });

    return {
      lang,
      showLoadMore,
      loadMore,
      paginatedData,
    };
  },
};
</script>

<template>
  <div class="flex justify-center text-[#212529]">
    <h1
      class="text-center text-xl sm:text-2xl lg:text-3xl font-thin pt-8 p-3 border-b border-gray-500"
    >
      {{ lang === "mn" ? "Манай баг" : "Our team" }}
    </h1>
  </div>

  <div
    v-if="!isLoading && ourTeam.length > 0"
    class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 text-[#212529] my-5 max-[1199px]:px-3"
  >
    <div
      v-for="member in ourTeam"
      :key="member.id"
      class="block space-y-1 sm:space-y-3 shadow-lg bg-white rounded-lg p-3"
    >
      <div class="flex justify-center">
        <img
          :src="member.image"
          alt="profile"
          class="h-40 w-40 md:w-52 md:h-52 rounded-full object-fit"
        />
      </div>
      <h2 class="text-base md:text-lg text-center">{{ member.name }}</h2>
      <h4 class="max-md:text-sm text-center font-thin">{{ member.job }}</h4>
      <p v-html="member.text" class="text-center font-thin max-sm:text-xs"></p>
    </div>
  </div>

  <div
    v-else-if="!isLoading && ourTeam.length === 0"
    class="flex justify-center items-center h-[70vh]"
  >
    {{
      lang === "mn"
        ? "Хуудас хоосон байна."
        : "No data available at the moment."
    }}
  </div>

  <div v-else class="flex justify-center items-center h-[70vh]">
    <BarLoading />
  </div>
</template>

<script>
import clientInstance from "@/lib/instance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import BarLoading from "@/components/UI/BarLoading.vue";

export default {
  name: "OurTeam",
  components: {
    BarLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const ourTeam = ref([]);
    const fetchBoardMembers = async () => {
      try {
        isLoading.value = true;
        const language = lang.value === "mn" ? 1 : 2;
        const res = await clientInstance.post("/ourteam/allLanguage", {
          language: language,
        });
        if (res.status === 200) {
          ourTeam.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchBoardMembers();
    });

    watch(lang, async () => {
      await fetchBoardMembers();
    });

    return { lang, isLoading, ourTeam };
  },
};
</script>

<template>
  <PublicationContainer
    :title="title"
    :data="education"
    :isLoading="isLoading"
  />
</template>

<script>
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";
import PublicationContainer from "@/containers/publications/PublicationContainer.vue";

export default {
  name: "EcologyEducationPub",
  components: {
    PublicationContainer,
  },
  setup() {
    const title = {
      mn: "Экологийн боловсрол ном",
      en: "Ecological Education Publication",
      color: "#007300",
    };
    const isLoading = ref(false);
    const education = ref([]);

    const fetchEcologyEducationPub = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          "/EcologipublicationWeb/publicationecologi"
        );
        if (res.status === 200) {
          education.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchEcologyEducationPub();
    });

    return {
      title,
      isLoading,
      education,
    };
  },
};
</script>

<template>
  <section>
    <div class="p-3 border-b">
      <button
        @click="cancelEditMode()"
        class="p-2 text-gray-500 text-xs rounded border border-gray-300 hover:bg-gray-400 hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />Буцах
      </button>
    </div>

    <div class="p-5">
      <div class="flex items-center gap-5 mt-5">
        <label for="image" class="w-[10rem] text-end">
          Слайд зураг:<span class="text-red-500">*</span>
        </label>
        <div class="space-y-3 w-full">
          <input
            id="image"
            name="image"
            type="file"
            class="w-full p-2 border rounded"
            :class="!mainImage && isSubmitted ? 'border-red-500' : ''"
            @change="onImageChange"
            accept="image/jpeg, image/png"
          />
        </div>
      </div>

      <div class="flex gap-5">
        <span class="w-[10rem]"></span>
        <div class="w-full">
          <span class="text-sky-500 text-xs">
            Зөвхөн jpg, jpeg, png өргөтгөлтэй зураг оруулна уу.
          </span>
          <div
            class="flex flex-col justify-center items-center w-full min-h-[30vh] mt-3"
          >
            <img
              :src="imageSrc"
              alt="image"
              :class="!mainImage ? 'w-14 h-14' : 'w-3/4 object-contain'"
            />
            <span v-if="!mainImage" class="text-xs text-gray-500"
              >Зураг байхгүй байна</span
            >
          </div>
        </div>
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="title" class="w-[10rem] text-end"
          >Слайд гарчиг:<span class="text-red-500">*</span></label
        >
        <input
          id="title"
          name="title"
          type="text"
          placeholder="Гарчиг"
          class="w-full p-2 border rounded"
          :class="!form.title && isSubmitted ? 'border-red-500' : ''"
          v-model="form.title"
        />
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="text" class="w-[10rem] text-end"
          >Слайд тайлбар:<span class="text-red-500">*</span></label
        >
        <input
          id="text"
          name="text"
          type="text"
          placeholder="Тайлбар"
          class="w-full p-2 border rounded"
          :class="!form.text && isSubmitted ? 'border-red-500' : ''"
          v-model="form.text"
        />
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="uploadSlider()"
          class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2"
          :class="
            form.id
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-[#28a745] hover:bg-[#208537]'
          "
        >
          <font-awesome-icon
            :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-plus'"
          />
          {{ form.id ? "Хадгалах" : "Нэмэх" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";

export default {
  name: "EditSlider",
  props: {
    slide: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        if (props.slide.image) {
          imageSrc.value = props.slide.image;
          mainImage.value = props.slide.image;
        } else {
          imageSrc.value = defaultImage;
          mainImage.value = null;
        }
      }
    };

    const form = ref({
      id: null,
      title: null,
      text: null,
    });

    onMounted(() => {
      try {
        if (props.slide) {
          imageSrc.value = props.slide.image;
          mainImage.value = props.slide.image;
          form.value.id = props.slide.id;
          form.value.title = props.slide.title;
          form.value.text = props.slide.text;
        }
      } catch (err) {
        console.error(err);
      }
    });

    const isSubmitted = ref(false);
    const validateForm = () => {
      return mainImage.value && form.value.title && form.value.text;
    };

    const uploadSlider = async () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      const formData = new FormData();
      formData.append("file", mainImage.value);
      formData.append("title", form.value.title);
      formData.append("text", form.value.text);

      try {
        let res;
        if (form.value.id) {
          formData.append("id", form.value.id);
          res = await clientInstance.post("/sliderweb/update", formData);
        } else {
          res = await clientInstance.post("/sliderweb/insert", formData);
        }

        if (res.status === 200) {
          isSubmitted.value = false;
          alert("Амжилттай шинэчлэгдлээ.");
          window.location.reload();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    const resetForm = () => {
      form.value = {
        id: null,
        title: null,
        text: null,
      };
      imageSrc.value = defaultImage;
      mainImage.value = null;
    };

    const cancelEditMode = () => {
      resetForm();
      emit("cancel-edit");
    };

    return {
      imageSrc,
      mainImage,
      onImageChange,
      form,
      isSubmitted,
      uploadSlider,
      cancelEditMode,
    };
  },
};
</script>

<template>
  <AdminNavbar>
    <main class="p-5">
      <div class="bg-white rounded-xl p-5">
        <div class="flex items-center mb-6 text-xs">
          <label for="account">И-мэйл хаяг:</label>
          <input
            id="account"
            name="account"
            type="email"
            placeholder="E-mail"
            class="border rounded p-1 ml-2 w-[15rem]"
            :class="!email && isSubmitted ? 'border-red-500' : ''"
            v-model="email"
          />
          <button
            @click="addNewAdmin"
            class="bg-green-500 p-1 text-white rounded ml-5 hover:bg-green-600"
          >
            <font-awesome-icon icon="fa-solid fa-user-plus" class="mr-1" />
            Админаар нэмэх
          </button>
        </div>

        <h1 class="text-center font-bold mb-4">Админы жагсаалт</h1>
        <table
          v-if="admins.length > 0"
          class="min-w-full table-auto border-collapse border border-gray-200"
        >
          <thead>
            <tr class="bg-gray-100 text-left">
              <th class="border border-gray-200 px-4 py-2 text-center">№</th>
              <th class="border border-gray-200 px-4 py-2 text-center">
                И-мэйл хаяг
              </th>
              <th class="border border-gray-200 px-4 py-2 text-center">
                Мэдээлэл засах
              </th>
              <th class="border border-gray-200 px-4 py-2 text-center">
                Устгах
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(admin, index) in admins"
              :key="admin.id"
              class="text-xs"
            >
              <td class="border border-gray-200 px-3 py-2 text-center">
                {{ index + 1 }}
              </td>

              <!-- Display email or input based on isEditing -->
              <td class="border border-gray-200 px-3 py-2">
                <template v-if="admin.isEditing">
                  <input v-model="admin.email" class="border p-1 w-full" />
                </template>
                <template v-else>
                  {{ admin.email }}
                </template>
              </td>

              <!-- Edit or Save button -->
              <td class="border border-gray-200 p-2">
                <div class="flex justify-center">
                  <button
                    v-if="admin.isEditing"
                    @click="saveAdmin(admin)"
                    class="bg-green-500 rounded text-white p-1 hover:bg-green-600"
                  >
                    Хадгалах
                  </button>
                  <button
                    v-else
                    @click="editAdmin(admin)"
                    class="bg-blue-500 rounded text-white p-1 hover:bg-blue-600"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-pen-square"
                      class="mr-1 text-sm"
                    />
                    Засварлах
                  </button>
                </div>
              </td>

              <!-- Delete button -->
              <td class="border border-gray-200 p-2">
                <div class="flex justify-center">
                  <button
                    @click="deleteAdmin(admin.id)"
                    class="bg-red-500 rounded text-white p-1 hover:bg-red-600"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-minus"
                      class="mr-1 text-sm"
                    />
                    Админаас хасах
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-else
          class="flex justify-center items-center h-[20vh] text-gray-500"
        >
          Жагсаалт хоосон байна.
        </div>

        <h1 class="text-center font-bold mt-8 mb-4">
          Админаар бүртгүүлэхээр ирсэн хүсэлт
        </h1>
        <table
          v-if="unregisteredAccounts.length > 0"
          class="min-w-full table-auto border-collapse border border-gray-200"
        >
          <thead>
            <tr class="bg-gray-100 text-left">
              <th class="border border-gray-200 px-4 py-2 text-center">№</th>
              <th class="border border-gray-200 px-4 py-2 text-center">
                И-мэйл хаяг
              </th>
              <th class="border border-gray-200 px-4 py-2 text-center">
                Хүсэлт илгээсэн огноо
              </th>
              <th class="border border-gray-200 px-4 py-2 text-center">
                Бүртгэх
              </th>
              <th class="border border-gray-200 px-4 py-2 text-center">
                Устгах
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(account, index) in unregisteredAccounts"
              :key="account.id"
              class="text-xs"
            >
              <td class="border border-gray-200 px-3 py-2 text-center">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-200 px-3 py-2">
                {{ account.email }}
              </td>
              <td class="border border-gray-200 px-3 py-2">
                {{ formatDate(account.registered_at) }}
              </td>
              <td class="border border-gray-200 p-2">
                <div class="flex justify-center">
                  <button
                    @click="addAdmin(account)"
                    class="bg-blue-500 rounded text-white p-1 hover:bg-blue-600"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-add"
                      class="mr-1 text-sm"
                    />
                    Админаар бүртгэх
                  </button>
                </div>
              </td>

              <td class="border border-gray-200 p-2">
                <div class="flex justify-center">
                  <button
                    @click="deleteUnregisteredAccount(account.id)"
                    class="bg-red-500 rounded text-white p-1 hover:bg-red-600"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash"
                      class="mr-1 text-sm"
                    />
                    Устгах
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-else
          class="flex justify-center items-center h-[20vh] text-gray-500"
        >
          Жагсаалт хоосон байна.
        </div>
      </div>
    </main>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/navigation/AdminNavbar.vue";
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";
import { formatDate } from "@/lib/formatDate";

export default {
  name: "AdminDashboard",
  components: {
    AdminNavbar,
  },
  setup() {
    const admins = ref([]);
    const unregisteredAccounts = ref([]);

    const fetchData = async () => {
      try {
        const res = await clientInstance.get("/account/all");
        if (res.status === 200)
          admins.value = res.data.account.map((admin) => ({
            ...admin,
            isEditing: false,
          }));
        const unRes = await clientInstance.get("/account/unregistered-all");
        if (unRes.status === 200)
          unregisteredAccounts.value = unRes.data.account;
      } catch (err) {
        console.error(err);
      }
    };

    const editAdmin = (admin) => {
      admin.isEditing = true;
    };

    const email = ref(null);
    const isSubmitted = ref(false);
    const addNewAdmin = async () => {
      isSubmitted.value = true;
      if (!email.value) {
        return;
      }

      try {
        const res = await clientInstance.post("/account/insert", {
          email: email.value,
        });
        if (res.status === 200) {
          isSubmitted.value = false;
          await fetchData();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        console.error(err);
        alert("Алдаа гарлаа.");
      }
    };

    const saveAdmin = async (admin) => {
      try {
        const res = await clientInstance.post("/account/update", {
          email: admin.email,
        });
        if (res.status === 200) {
          admin.isEditing = false;
          alert("Амжилттай шинэчлэгдлээ.");
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        console.error(err);
        alert("Алдаа гарлаа.");
      }
    };

    const deleteAdmin = async (adminId) => {
      if (!window.confirm(`Админыг устгах уу?`)) {
        return;
      }

      try {
        const res = await clientInstance.post("/account/delete", {
          id: adminId,
        });
        if (res.status === 200) {
          admins.value = admins.value.filter((admin) => admin.id !== adminId);
        }
      } catch (err) {
        console.error(err);
        alert("Алдаа гарлаа.");
      }
    };

    onMounted(() => {
      fetchData();
    });

    //unregistered accounts
    const addAdmin = async (account) => {
      try {
        const res = await clientInstance.post("/account/insert", account);
        if (res.status === 200) {
          await deleteUnregisteredAccount(account.id);
          await fetchData();
        }
      } catch (err) {
        alert("Алдаа гарлаа.");
        console.error(err);
      }
    };

    const deleteUnregisteredAccount = async (accountId) => {
      try {
        const res = await clientInstance.post("/account/unregister-delete", {
          id: accountId,
        });
        if (res.status === 200) {
          unregisteredAccounts.value = unregisteredAccounts.value.filter(
            (account) => account.id !== accountId
          );
        }
      } catch (err) {
        alert("Алдаа гарлаа.");
        console.error(err);
      }
    };

    return {
      admins,
      editAdmin,
      saveAdmin,
      deleteAdmin,
      addAdmin,
      deleteUnregisteredAccount,
      unregisteredAccounts,
      formatDate,
      email,
      addNewAdmin,
      isSubmitted,
    };
  },
};
</script>

<template>
  <section>
    <div class="flex justify-between items-center p-3 border-b">
      <button
        @click="cancelEditMode()"
        class="p-2 text-gray-500 text-xs rounded border border-gray-300 hover:bg-gray-400 hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />Буцах
      </button>
      <h1 class="text-center uppercase text-lg font-bold text-sky-500">
        Асуулт шинээр нэмэх
      </h1>
      <span></span>
    </div>

    <div class="p-5">
      <div class="flex gap-5 mt-5">
        <label for="title" class="w-[10rem] text-end mt-2"
          >Сорилын нэр:<span class="text-red-500">*</span></label
        >
        <div class="block w-full">
          <select
            v-if="!newCategoryShowing"
            name="title"
            id="title"
            class="w-full p-2 border rounded"
            :class="!form.title && isSubmitted ? 'border-red-500' : ''"
            v-model="form.title"
          >
            <option
              v-for="category in categories"
              :key="category.categoryId"
              :value="category.title"
            >
              {{ category.title }}
            </option>
          </select>

          <!-- <button
            v-if="!newCategoryShowing"
            @click="showNewCategory()"
            class="flex items-center text-amber-500 mt-2 hover:text-green-500"
          >
            <div
              class="flex justify-center items-center h-4 w-4 bg-amber-500 rounded-full mr-1"
            >
              <font-awesome-icon icon="fa-solid fa-plus" class="text-white" />
            </div>
            Шинээр сорил нэмэх
          </button> -->

          <div v-if="newCategoryShowing" class="flex items-center gap-3">
            <input
              id="newCategory"
              name="newCategory"
              type="text"
              placeholder="Шинэ сорил"
              class="w-full p-2 border rounded"
              v-model="newCategory"
            />
            <button
              @click="cancelNewCategory()"
              class="flex justify-center items-center h-4 w-4 bg-red-500 text-white rounded-full hover:bg-red-600"
              type="button"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="question" class="w-[10rem] text-end"
          >Асуулт:<span class="text-red-500">*</span></label
        >
        <textarea
          name="question"
          id="question"
          class="w-full p-2 border rounded"
          rows="4"
          placeholder="Асуулт"
          :class="!form.question && isSubmitted ? 'border-red-500' : ''"
          v-model="form.question"
        ></textarea>
      </div>

      <div class="flex gap-5 mt-5">
        <h6 class="w-[10rem] text-end mt-2">
          Хариулт:<span class="text-red-500">*</span>
        </h6>
        <div class="block w-full">
          <div
            v-for="(answer, index) in form.answers"
            :key="index"
            class="flex items-center gap-2 w-full mt-3"
          >
            <span class="text-xs">{{ index + 1 }}.</span>
            <input
              type="text"
              placeholder="Хариулт"
              class="w-full p-2 border rounded"
              :class="!answer.question && isSubmitted ? 'border-red-500' : ''"
              v-model="answer.question"
            />
            <input
              type="text"
              placeholder="Оноо"
              class="w-[5rem] p-2 border rounded"
              :class="!answer.point && isSubmitted ? 'border-red-500' : ''"
              v-model="answer.point"
            />
            <button
              @click="removeAnswer(index)"
              class="flex justify-center items-center h-4 w-5 bg-red-500 text-white rounded-full hover:bg-red-600"
              type="button"
            >
              <font-awesome-icon icon="fa-solid fa-minus" />
            </button>
          </div>

          <p
            v-if="form.answers.length > 0"
            class="text-sky-500 text-xs mt-1 mb-2 ml-6"
          >
            Оноон дээр зөвхөн тоон утга оруулна уу.
          </p>
        </div>
      </div>

      <div v-if="form.answers.length < 5" class="flex">
        <span class="w-[12rem]"></span>
        <div class="w-full">
          <button
            @click="addMoreAnswer()"
            class="flex items-center text-amber-500 hover:text-green-500"
          >
            <div
              class="flex justify-center items-center h-4 w-4 bg-amber-500 rounded-full mr-1"
            >
              <font-awesome-icon
                icon="fa-solid fa-plus"
                class="text-white text-xs"
              />
            </div>
            Хариулт нэмэх
          </button>
        </div>
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="insertQuizQuestion"
          class="flex justify-center items-center gap-1 w-[15rem] text-white rounded py-2 bg-[#28a745] hover:bg-[#208537]"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />Шинэ асуултыг хадгалах
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, watch } from "vue";
import clientInstance from "@/lib/instance";

export default {
  name: "AddQuizQuestion",
  props: {
    categories: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const newCategory = ref(null);

    const form = ref({
      categoryId: props.categories[0].categoryId || "",
      title: props.categories[0]?.title || "",
      question: null,
      answers: [{ question: "", point: "" }],
    });

    const resetForm = async () => {
      await fetchCategories();

      form.value = {
        categoryId: props.categories[0].categoryId || "",
        title: props.categories[0]?.title || "",
        question: null,
        answers: [{ question: "", point: "" }],
      };
      newCategory.value = null;
      newCategoryShowing.value = false;
    };

    const cancelEditMode = () => {
      resetForm();
      emit("cancel-edit", "A");
    };

    const fetchCategories = () => {
      emit("fetch-categories");
    };

    watch([newCategory], () => {
      form.value.categoryId = null;
      form.value.title = null;
    });

    // const addMoreAnswer = () => {
    //   form.value.answers.push({ question: "", point: "" });
    // };

    const addMoreAnswer = () => {
      if (form.value.answers.length < 5) {
        form.value.answers.push({ question: "", point: "" });
      } else {
        alert("5 хүртэлх хариулт нэмэх боломжтой.");
      }
    };

    const removeAnswer = (index) => {
      form.value.answers.splice(index, 1);
    };

    const newCategoryShowing = ref(false);
    const showNewCategory = () => {
      newCategoryShowing.value = true;
    };
    const cancelNewCategory = () => {
      newCategory.value = null;
      newCategoryShowing.value = false;
    };

    const isSubmitted = ref(false);
    const validateForm = () => {
      return (
        form.value.question &&
        form.value.answers.every((answer) => answer.question && answer.point)
      );
    };

    const insertQuizQuestion = async () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      try {
        let res;

        if (newCategoryShowing.value && newCategory.value) {
          res = await clientInstance.post("/quizweb/insertWithNewCategory", {
            title: newCategory.value,
            question: form.value.question,
            answers: form.value.answers,
          });
        } else {
          res = await clientInstance.post("/quizweb/insert", {
            categoryId: form.value.categoryId,
            title: form.value.title,
            question: form.value.question,
            answers: form.value.answers,
          });
        }

        if (res.status === 200) {
          alert("Амжилттай нэмэгдлээ.");
          isSubmitted.value = false;
          await resetForm();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    return {
      form,
      cancelEditMode,
      isSubmitted,
      insertQuizQuestion,
      addMoreAnswer,
      removeAnswer,
      newCategory,
      showNewCategory,
      cancelNewCategory,
      newCategoryShowing,
    };
  },
};
</script>

import clientInstance from "@/lib/instance";

export default {
  namespaced: true,
  state() {
    return {
      admin: {},
    };
  },

  getters: {
    getAdminInformation(state) {
      return state.admin;
    },
  },

  mutations: {
    setAdminInformation(state, payload) {
      state.admin = payload;
    },
  },

  actions: {
    async fetchAdminInformation({ commit }, payload) {
      try {
        const response = await clientInstance.post("/account/login", payload);
        if (response.status === 200) {
          commit("setAdminInformation", response.data.account);
          return response;
        }
      } catch (err) {
        return err;
      }
    },
  },
};

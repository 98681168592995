<template>
  <ListContainer
    :title="title"
    :data="evaluation"
    :details="false"
    :isLoading="isLoading"
  />
</template>

<script>
import ListContainer from "@/containers/list/ListContainer.vue";
import clientInstance from "@/lib/instance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "DonorEvaluation",
  components: {
    ListContainer,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const title = {
      mn: "Хандивлагчийн үнэлгээ",
      en: "Donor's Evaluation",
    };

    const isLoading = ref(false);
    const evaluation = ref([]);

    const fetchEvaluations = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/donorsEvaluationWeb/getDonors");
        if (res.status === 200) {
          evaluation.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchEvaluations();
    });

    watch(lang, async () => {
      await fetchEvaluations();
    });

    return { title, isLoading, evaluation, lang };
  },
};
</script>

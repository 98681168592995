<template>
  <section>
    <div class="p-3 border-b">
      <button
        @click="cancelEditMode()"
        class="p-2 text-gray-500 text-xs rounded border border-gray-300 hover:bg-gray-400 hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />Буцах
      </button>
    </div>

    <div class="p-5">
      <div class="flex gap-5 mt-5">
        <label for="year" class="w-[10rem] text-end mt-2"
          >Он:<span class="text-red-500">*</span></label
        >
        <div class="block w-full">
          <input
            id="year"
            name="year"
            type="text"
            placeholder="Он"
            class="w-full p-2 border rounded"
            :class="!form.year && isSubmitted ? 'border-red-500' : ''"
            v-model="form.year"
          />
          <span class="text-sky-500 text-xs">
            Зөвхөн тоон утга оруулна уу.
          </span>
        </div>
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="title" class="w-[10rem] text-end"
          >Гарчиг:<span class="text-red-500">*</span></label
        >
        <input
          id="title"
          name="title"
          type="text"
          placeholder="Гарчиг"
          class="w-full p-2 border rounded"
          :class="!form.title && isSubmitted ? 'border-red-500' : ''"
          v-model="form.title"
        />
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="text" class="w-[10rem] text-end"
          >Тэмдэглэл:<span class="text-red-500">*</span></label
        >
        <input
          id="text"
          name="text"
          type="text"
          placeholder="Тэмдэглэл"
          class="w-full p-2 border rounded"
          :class="!form.text && isSubmitted ? 'border-red-500' : ''"
          v-model="form.text"
        />
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="language" class="w-[10rem] text-end"
          >Хэл:<span class="text-red-500">*</span></label
        >
        <select
          name="language"
          id="language"
          class="w-full p-2 border rounded"
          :class="!form.language && isSubmitted ? 'border-red-500' : ''"
          v-model="form.language"
        >
          <option value="1">Монгол</option>
          <option value="2">Англи</option>
        </select>
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="uploadTimeline()"
          class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2"
          :class="
            form.id
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-[#28a745] hover:bg-[#208537]'
          "
        >
          <font-awesome-icon
            :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-plus'"
          />
          {{ form.id ? "Хадгалах" : "Нэмэх" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";

export default {
  name: "EditChronicles",
  props: {
    timeline: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const form = ref({
      id: null,
      year: null,
      title: null,
      text: null,
      language: 1,
    });

    onMounted(() => {
      try {
        if (props.timeline) {
          form.value.id = props.timeline.id;
          form.value.year = props.timeline.year;
          form.value.title = props.timeline.title;
          form.value.text = props.timeline.text;
          form.value.language = props.timeline.language;
        }
      } catch (err) {
        console.error(err);
      }
    });

    const isSubmitted = ref(false);
    const validateForm = () => {
      return form.value.year;
    };

    const uploadTimeline = async () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      try {
        let res;
        if (form.value.id) {
          res = await clientInstance.post("/chronicles/update", form.value);
        } else {
          res = await clientInstance.post("/chronicles/insert", form.value);
        }

        if (res.status === 200) {
          isSubmitted.value = false;
          alert("Амжилттай шинэчлэгдлээ.");
          window.location.reload();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    const resetForm = () => {
      form.value = {
        id: null,
        year: null,
        title: null,
        text: null,
        language: 1,
      };
    };

    const cancelEditMode = () => {
      resetForm();
      emit("cancel-edit");
    };

    return {
      form,
      isSubmitted,
      uploadTimeline,
      cancelEditMode,
    };
  },
};
</script>

<template>
  <section>
    <div class="flex justify-between items-center p-3 border-b">
      <button
        @click="cancelEditMode()"
        class="p-2 text-gray-500 text-xs rounded border border-gray-300 hover:bg-gray-400 hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />Буцах
      </button>
      <h1 class="text-center uppercase text-lg font-bold text-sky-500">
        Асуулт засах
      </h1>
      <span></span>
    </div>

    <div class="p-5">
      <div class="flex gap-5 mt-5">
        <label for="title" class="w-[10rem] text-end mt-2"
          >Сорилын нэр:<span class="text-red-500">*</span></label
        >
        <div class="block w-full">
          <select
            name="title"
            id="title"
            class="w-full p-2 border rounded"
            :class="!form.title && isSubmitted ? 'border-red-500' : ''"
            v-model="form.title"
          >
            <option
              v-for="category in categories"
              :key="category.Pid"
              :value="category.title"
            >
              {{ category.title }}
            </option>
          </select>
        </div>
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="question" class="w-[10rem] text-end"
          >Асуулт:<span class="text-red-500">*</span></label
        >
        <textarea
          name="question"
          id="question"
          class="w-full p-2 border rounded"
          rows="4"
          placeholder="Асуулт"
          :class="!form.answer && isSubmitted ? 'border-red-500' : ''"
          v-model="form.answer"
        ></textarea>
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="answer" class="w-[10rem] text-end"
          >Хариулт:<span class="text-red-500">*</span></label
        >
        <input
          id="answer"
          name="answer"
          type="text"
          placeholder="Хариулт"
          class="w-full p-2 border rounded"
          :class="!form.question && isSubmitted ? 'border-red-500' : ''"
          v-model="form.question"
        />
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="point" class="w-[10rem] text-end"
          >Оноо:<span class="text-red-500">*</span></label
        >
        <input
          id="point"
          name="point"
          type="text"
          placeholder="Оноо"
          class="w-full p-2 border rounded"
          :class="!form.point && isSubmitted ? 'border-red-500' : ''"
          v-model="form.point"
        />
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="updateQuizQuestion()"
          class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2 bg-blue-500 hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-pen" />Хадгалах
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import clientInstance from "@/lib/instance";

export default {
  name: "EditQuizQuestion",
  props: {
    categories: {
      type: Object,
    },
    question: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const form = ref({
      Pid: props.question.Pid,
      categoryId: props.question.categoryId,
      id: props.question.id,
      title: props.question.title,
      question: props.question.question,
      answer: props.question.answer,
      point: props.question.point,
    });

    const resetForm = () => {
      form.value = {
        Pid: null,
        categoryId: null,
        id: null,
        title: null,
        question: null,
        answer: null,
        point: null,
      };
    };

    const cancelEditMode = () => {
      resetForm();
      emit("cancel-edit", "A");
    };

    const isSubmitted = ref(false);
    const validateForm = () => {
      return (
        form.value.Pid &&
        form.value.categoryId &&
        form.value.id &&
        form.value.title &&
        form.value.question &&
        form.value.answer &&
        form.value.point
      );
    };

    const updateQuizQuestion = async () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      try {
        const res = await clientInstance.post("/quizweb/update", form.value);

        if (res.status === 200) {
          isSubmitted.value = false;
          alert("Амжилттай шинэчлэгдлээ.");
          cancelEditMode();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    return { form, cancelEditMode, updateQuizQuestion, isSubmitted };
  },
};
</script>

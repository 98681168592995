<template>
  <section>
    <div class="p-3 border-b">
      <button
        @click="cancelEditMode()"
        class="p-2 text-gray-500 text-xs rounded border border-gray-300 hover:bg-gray-400 hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />Буцах
      </button>
    </div>

    <div class="p-5">
      <div class="flex items-center gap-5 mt-5">
        <label class="w-[10rem] text-end">
          Зураг:<span class="text-red-500">*</span>
        </label>
        <div class="space-y-3 w-full">
          <input
            type="file"
            class="w-full p-2 border rounded"
            :class="!mainImage && isSubmitted ? 'border-red-500' : ''"
            @change="onImageChange"
            accept="image/jpeg, image/png"
          />
        </div>
      </div>

      <div class="flex gap-5">
        <span class="w-[10rem]"></span>
        <div class="w-full">
          <span class="text-sky-500 text-xs">
            Зөвхөн jpg, jpeg, png өргөтгөлтэй зураг оруулна уу.
          </span>
          <div
            class="flex flex-col justify-center items-center w-full min-h-[30vh] mt-3"
          >
            <img
              :src="imageSrc"
              alt="image"
              :class="!mainImage ? 'w-14 h-14' : 'w-1/3 object-contain'"
            />
            <span v-if="!mainImage" class="text-xs text-gray-500"
              >Зураг байхгүй байна</span
            >
          </div>
        </div>
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="title" class="w-[10rem] text-end"
          >Гарчиг:<span class="text-red-500">*</span></label
        >
        <input
          id="title"
          name="title"
          type="text"
          placeholder="Нийтлэлийн гарчиг"
          class="w-full p-2 border rounded"
          :class="!form.title && isSubmitted ? 'border-red-500' : ''"
          v-model="form.title"
        />
      </div>

      <div class="flex gap-5 mt-5">
        <label for="text" class="w-[10rem] text-end mt-3"
          >Агуулга:<span class="text-red-500">*</span></label
        >
        <div class="prose" style="max-width: 900px">
          <ckeditor
            :editor="Editor"
            v-model="editorData"
            :config="editorConfig"
          />
        </div>
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="uploadPublication()"
          class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2 hover:scale-105"
          :class="form.id ? 'bg-blue-500' : 'bg-[#28a745]'"
        >
          <font-awesome-icon
            :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-plus'"
          />
          {{ form.id ? "Засварыг хадгалах" : "Нийтлэл нэмэх" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from "vue";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Ckeditor } from "@ckeditor/ckeditor5-vue";

export default {
  name: "EditPublication",
  props: {
    publication: {
      type: Object,
    },
  },
  components: {
    Ckeditor,
  },
  setup(props, { emit }) {
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        if (props.publication.image) {
          imageSrc.value = props.publication.image;
          mainImage.value = props.publication.image;
        } else {
          imageSrc.value = defaultImage;
          mainImage.value = null;
        }
      }
    };

    const form = ref({
      id: null,
      title: null,
    });
    const editorData = ref(null);

    onMounted(() => {
      try {
        if (props.publication) {
          imageSrc.value = props.publication.image;
          mainImage.value = props.publication.image;
          form.value.id = props.publication.id;
          form.value.title = props.publication.title;
          editorData.value = props.publication.text;
        }
      } catch (err) {
        console.error(err);
      }
    });

    const resetForm = () => {
      form.value = {
        id: null,
        title: null,
      };
      imageSrc.value = defaultImage;
      mainImage.value = null;
      editorData.value = null;
    };

    const cancelEditMode = () => {
      resetForm();
      emit("cancel-edit");
    };

    const isSubmitted = ref(false);
    const validateForm = () => {
      return mainImage.value && editorData.value && form.value.title;
    };

    const uploadPublication = () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      try {
        emit("upload-pub", form.value, mainImage.value, editorData.value);
      } catch (err) {
        return err;
      }
    };

    /* CKEDITOR */
    class MyUploadAdapter {
      constructor(loader) {
        this.loader = loader;
      }

      upload() {
        return this.loader.file.then(
          (file) =>
            new Promise((resolve, reject) => {
              this._initRequest();
              this._initListeners(resolve, reject, file);
              this._sendRequest(file);
            })
        );
      }

      abort() {
        if (this.xhr) {
          this.xhr.abort();
        }
      }

      _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open("POST", "https://cce.mn/newsweb/ckupload", true);
        xhr.responseType = "json";
      }

      _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
          const response = xhr.response;

          if (!response || response.error) {
            return reject(
              response && response.error
                ? response.error.message
                : genericErrorText
            );
          }

          console.log(response);
          resolve({
            default: response.url,
          });
        });

        if (xhr.upload) {
          xhr.upload.addEventListener("progress", (evt) => {
            if (evt.lengthComputable) {
              loader.uploadTotal = evt.total;
              loader.uploaded = evt.loaded;
            }
          });
        }
      }

      _sendRequest(file) {
        const data = new FormData();
        data.append("file", file);
        this.xhr.send(data);
      }
    }

    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    }

    const editorConfig = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      removePlugins: ["MediaEmbedToolbar", "Markdown"],
      alignment: {
        options: ["left", "right", "center", "justify"],
      },
      language: "en",
      licenseKey: "",
    };

    return {
      imageSrc,
      mainImage,
      form,
      editorData,
      onImageChange,
      cancelEditMode,
      uploadPublication,
      isSubmitted,
      Editor,
      editorConfig,
    };
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 700px;
}
</style>

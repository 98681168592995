<template>
  <div class="flex justify-center text-[#212529]">
    <h1
      class="text-center text-xl sm:text-2xl lg:text-3xl font-thin pt-8 p-3 border-b border-gray-500"
    >
      {{ lang === "mn" ? "Хамтрагч байгууллагууд" : "Partners" }}
    </h1>
  </div>

  <div
    v-if="!isLoading && partners.length > 0"
    class="grid grid-cols-1 min-[400px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 text-[#212529] my-5 max-[1199px]:px-3"
  >
    <a
      v-for="partner in partners"
      :key="partner.id"
      :href="partner.title"
      target="_blank"
      class="bg-white p-5 rounded hover:scale-105"
    >
      <div class="flex justify-center items-center">
        <img
          :src="partner.image"
          alt="profile"
          class="w-28 h-28 md:w-40 md:h-40 object-contain"
        />
      </div>
      <p class="max-sm:text-sm text-center mt-3">{{ partner.text }}</p>
    </a>
  </div>

  <div
    v-else-if="!isLoading && partners.length === 0"
    class="flex justify-center items-center h-[70vh]"
  >
    {{
      lang === "mn"
        ? "Хуудас хоосон байна."
        : "No data available at the moment."
    }}
  </div>

  <div v-else class="flex justify-center items-center h-[70vh]">
    <BarLoading />
  </div>
</template>

<script>
import clientInstance from "@/lib/instance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import BarLoading from "../../components/UI/BarLoading";

export default {
  name: "CompanyPartners",
  components: {
    BarLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const partners = ref([]);
    const fetchPartners = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/partnersWeb/partners");
        if (res.status === 200) {
          partners.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchPartners();
    });

    watch(lang, async () => {
      await fetchPartners();
    });

    return { lang, isLoading, partners };
  },
};
</script>

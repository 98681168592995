<template>
  <div class="flex text-gray-800">
    <!-- bosoo nav -->
    <nav
      class="sticky top-0 bg-white h-screen w-[20vw] border-r p-2 overflow-y-scroll"
    >
      <div class="flex items-center gap-1">
        <img
          src="../../assets/images/logo/logo.png"
          alt="logo"
          class="w-[5rem] object-contain"
        />
        <h1 class="uppercase text-primary font-bold">
          Иргэний боловсролын төв
        </h1>
      </div>

      <ul class="text-sm text-nowrap mt-5">
        <li>
          <router-link
            to="/admin/dashboard"
            class="flex items-center gap-2 cursor-pointer uppercase p-2"
            :class="
              isRoute === '/admin/dashboard'
                ? 'bg-primary rounded text-white'
                : 'hover:text-primary'
            "
          >
            <font-awesome-icon
              icon="fa-solid fa-chart-simple"
              class="text-sm"
            />
            dashboard
          </router-link>
        </li>

        <li v-for="(route, index) in menu" :key="index">
          <div class="relative text-center my-4">
            <hr class="border-t border-gray-300" />
            <p
              class="absolute top-[-8px] left-4 bg-white text-xs text-gray-500 px-1 uppercase"
            >
              {{ route.title }}
            </p>
          </div>

          <router-link
            v-for="(child, index) in route.children"
            :key="index"
            :to="`/admin${child.to}`"
            class="flex items-center gap-2 cursor-pointer p-2"
            :class="
              isRoute === `/admin${child.to}`
                ? 'bg-primary rounded text-white'
                : 'hover:text-primary'
            "
          >
            <font-awesome-icon
              :icon="`fa-solid fa-${child.icon}`"
              class="text-sm"
            />
            {{ child.name }}
          </router-link>
        </li>

        <li class="flex justify-center my-5">
          <button
            @click="logout()"
            class="border border-primary rounded py-2 px-4 text-primary hover:text-white hover:bg-primary"
          >
            Системээс гарах<font-awesome-icon
              icon="fa-solid fa-arrow-right-from-bracket"
              class="ml-2"
            />
          </button>
        </li>
      </ul>
    </nav>

    <!-- Хөндлөн navbar -->
    <div class="w-[80vw]">
      <div
        class="sticky top-0 flex justify-between items-center w-full bg-white text-gray-500 pl-3 pr-5 py-2 border-b z-20"
      >
        <div></div>
        <button @click="logout()" class="cursor-pointer hover:text-blue-500">
          <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
        </button>
      </div>

      <div class="bg-gray-100 p-3 space-y-3 min-h-[92vh] text-sm">
        <slot></slot>
      </div>

      <!-- Footer -->
      <footer class="w-full bg-gray-200 font-bold text-center text-xs p-1">
        <a href="https://bowsys.mn" target="_blank" class="hover:underline">
          &copy; BOW LLC - 2024
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Cookies from "js-cookie";

export default {
  name: "AdminNavbar",
  setup() {
    const route = useRoute();
    const isRoute = computed(() => route.path);

    const menu = ref([
      {
        title: "Нүүр хуудас",
        children: [
          {
            name: "Слайд зургууд",
            to: "/slider",
            icon: "image",
          },
        ],
      },
      {
        title: "Бидний тухай",
        children: [
          {
            name: "Удирдах зөвлөл",
            to: "/board-members",
            icon: "users",
          },
          {
            name: "Манай баг",
            to: "/our-team",
            icon: "users",
          },
          {
            name: "Түүхэн товчоо",
            to: "/chronicles",
            icon: "clock",
          },
          {
            name: "Хамтрагч байгууллага",
            to: "/partners",
            icon: "building",
          },
        ],
      },
      // {
      //   title: "Хөтөлбөр",
      //   children: [
      //     {
      //       name: "Иргэний боловсрол",
      //       to: "/civic-education",
      //       icon: "school",
      //     },
      //     {
      //       name: "Иргэдийн оролцоо",
      //       to: "",
      //       icon: "hand",
      //     },
      //     {
      //       name: "Залуучуудын хөгжил",
      //       to: "",
      //       icon: "child",
      //     },
      //     {
      //       name: "Экологийн боловсрол",
      //       to: "",
      //       icon: "tree",
      //     },
      //   ],
      // },
      {
        title: "Мэдээ",
        children: [
          {
            name: "Мэдээ нэмэх",
            to: "/add-news",
            icon: "newspaper",
          },
          {
            name: "Мэдээ засварлах",
            to: "/edit-news",
            icon: "pen-to-square",
          },
        ],
      },
      {
        title: "Архив",
        children: [
          {
            name: "Тайлан",
            to: "/archive/reports",
            icon: "paperclip",
          },
          {
            name: "Амжилтын түүхүүд",
            to: "/archive/stories",
            icon: "book",
          },
          {
            name: "Үр шим хүртэгчдийн сэтгэгдэл",
            to: "/archive/feedback",
            icon: "comment",
          },
          {
            name: "Хандивын үнэлгээ",
            to: "/archive/evaluation",
            icon: "hand-holding-dollar",
          },
        ],
      },
      {
        title: "Нийтлэлүүд",
        children: [
          {
            name: "Иргэний боловсрол",
            to: "/pub/civic",
            icon: "school",
          },
          {
            name: "Иргэдийн оролцоо",
            to: "/pub/participation",
            icon: "hand",
          },
          {
            name: "Залуучуудын хөгжил",
            to: "/pub/youth",
            icon: "child",
          },
          {
            name: "Экологийн боловсрол",
            to: "/pub/ecology",
            icon: "tree",
          },
        ],
      },
      {
        title: "Yvote application",
        children: [
          {
            name: "Quiz",
            to: "/quiz",
            icon: "trophy",
          },
          {
            name: "Календар",
            to: "/calendar",
            icon: "calendar-days",
          },
        ],
      },
    ]);

    const router = useRouter();
    const logout = () => {
      Cookies.remove("token");
      router.push("/admin/login");
    };

    return { menu, isRoute, logout };
  },
};
</script>

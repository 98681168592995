<template>
  <div class="flex justify-center text-[#212529]">
    <h1
      class="text-center text-xl sm:text-2xl lg:text-3xl font-thin pt-8 p-3 border-b border-gray-500"
    >
      {{ lang === "mn" ? "Түүхэн товчоо" : "Chronicles" }}
    </h1>
  </div>

  <table class="my-10 w-full">
    <tbody>
      <tr v-for="(timeline, index) in timelines" :key="index">
        <td
          v-if="index % 2 === 0"
          class="w-2/5 align-middle pr-1 sm:pr-3 max-[1199px]:pl-3"
        >
          <p
            v-for="chronicle in timeline.chronicles"
            :key="chronicle.title"
            class="flex flex-col"
          >
            <span class="font-bold mb-1 text-sm">{{ chronicle.title }}</span>
            <span class="text-xs">{{ chronicle.text }}</span>
          </p>
        </td>
        <td v-else></td>

        <td
          class="w-1/5 py-5 text-center text-white text-xl align-middle"
          :style="{
            backgroundColor: backgroundColors[index % backgroundColors.length],
          }"
        >
          {{ timeline.year }}
        </td>

        <td
          v-if="index % 2 !== 0"
          class="w-2/5 align-middle text-end pl-1 sm:pl-3 max-[1199px]:pr-3"
        >
          <p
            v-for="chronicle in timeline.chronicles"
            :key="chronicle.title"
            class="flex flex-col"
          >
            <span class="font-bold mb-1 text-sm">{{ chronicle.title }}</span>
            <span class="text-xs">{{ chronicle.text }}</span>
          </p>
        </td>
        <td v-else></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import clientInstance from "@/lib/instance";

export default {
  name: "CompanyChronicles",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const timelines = ref([]);

    const fetchTimeline = async () => {
      try {
        const language = lang.value === "mn" ? 1 : 2;
        const res = await clientInstance.get(
          `/chronicles/allByGroup?language=${language}`
        );
        if (res.status === 200) timelines.value = res.data;
      } catch (err) {
        return err;
      }
    };

    onMounted(() => {
      fetchTimeline();
    });

    watch(lang, () => {
      fetchTimeline();
    });

    const backgroundColors = ref([
      "#20387D",
      "#1F407B",
      "#1E4879",
      "#1E5178",
      "#1D5976",
      "#1D6175",
      "#1B7271",
      "#1B7A70",
      "#1A836E",
      "#1A8B6D",
      "#19936B",
      "#199C6A",
      "#2EA367",
      "#43AA65",
      "#58B162",
      "#6DB960",
      "#82C05D",
      "#97C75B",
      "#ACCF58",
      "#C1D656",
      "#D6DC53",
      "#E9E250",
      "#EAD65E",
      "#E9C86C",
      "#E8B97A",
      "#E7AB87",
      "#E59C95",
      "#E48EA3",
      "#E37FB0",
    ]);

    return { lang, timelines, backgroundColors };
  },
};
</script>

<template>
  <section>
    <div class="p-3 border-b">
      <button
        @click="toggleEditMode"
        class="p-2 text-gray-500 text-xs rounded border border-gray-300 hover:bg-gray-400 hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />Буцах
      </button>
    </div>

    <div class="p-5">
      <div class="flex items-center gap-5 mt-5">
        <label class="w-[10rem] text-end">
          Зураг:<span class="text-red-500">*</span>
        </label>
        <div class="space-y-3 w-full">
          <input
            type="file"
            class="w-full p-2 border rounded"
            :class="!mainImage && isSubmitted ? 'border-red-500' : ''"
            @change="onImageChange"
            accept="image/jpeg, image/png"
          />
        </div>
      </div>

      <div class="flex gap-5">
        <span class="w-[10rem]"></span>
        <div class="w-full">
          <span class="text-sky-500 text-xs">
            Зөвхөн jpg, jpeg, png өргөтгөлтэй зураг оруулна уу.
          </span>
          <div
            class="flex flex-col justify-center items-center w-full min-h-[30vh] mt-3"
          >
            <img
              :src="imageSrc"
              alt="image"
              :class="!mainImage ? 'w-14 h-14' : 'w-1/3 object-contain'"
            />
            <span v-if="!mainImage" class="text-xs text-gray-500"
              >Зураг байхгүй байна</span
            >
          </div>
        </div>
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="name" class="w-[10rem] text-end"
          >Овог нэр:<span class="text-red-500">*</span></label
        >
        <input
          id="name"
          name="name"
          type="text"
          placeholder="Нэр"
          class="w-full p-2 border rounded"
          :class="!form.name && isSubmitted ? 'border-red-500' : ''"
          v-model="form.name"
        />
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="job" class="w-[10rem] text-end"
          >Албан тушаал:<span class="text-red-500">*</span></label
        >
        <input
          id="job"
          name="job"
          type="text"
          placeholder="Албан тушаал"
          class="w-full p-2 border rounded"
          :class="!form.job && isSubmitted ? 'border-red-500' : ''"
          v-model="form.job"
        />
      </div>

      <div class="flex gap-5 mt-5">
        <label for="job" class="w-[10rem] text-end mt-2"
          >Намтар:<span class="text-red-500">*</span></label
        >
        <div class="prose" style="max-width: 930px">
          <ckeditor
            :editor="Editor"
            v-model="editorData"
            :config="editorConfig"
          />
        </div>
      </div>

      <div class="flex items-center gap-5 mt-5">
        <label for="language" class="w-[10rem] text-end"
          >Хэл:<span class="text-red-500">*</span></label
        >
        <select
          name="language"
          id="language"
          class="w-full p-2 border rounded"
          :class="!form.language && isSubmitted ? 'border-red-500' : ''"
          v-model="form.language"
        >
          <option value="1">Монгол</option>
          <option value="2">Англи</option>
        </select>
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="uploadMember()"
          class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2"
          :class="
            form.id
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-[#28a745] hover:bg-[#208537]'
          "
        >
          <font-awesome-icon
            :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-plus'"
          />
          {{ form.id ? "Хадгалах" : "Нэмэх" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Ckeditor } from "@ckeditor/ckeditor5-vue";

export default {
  name: "EditOurTeam",
  props: {
    member: {
      type: Object,
    },
  },
  components: {
    Ckeditor,
  },
  setup(props, { emit }) {
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        if (props.member.image) {
          imageSrc.value = props.member.image;
          mainImage.value = props.member.image;
        } else {
          imageSrc.value = defaultImage;
          mainImage.value = null;
        }
      }
    };

    const form = ref({
      id: null,
      job: null,
      language: 1,
      name: null,
    });
    const editorData = ref("");

    onMounted(() => {
      try {
        if (props.member) {
          imageSrc.value = props.member.image;
          mainImage.value = props.member.image;
          form.value.id = props.member.id;
          form.value.job = props.member.job;
          form.value.language = props.member.language;
          form.value.name = props.member.name;
          editorData.value = props.member.text;
        }
      } catch (err) {
        console.error(err);
      }
    });

    const isSubmitted = ref(false);
    const validateForm = () => {
      return (
        mainImage.value &&
        form.value.job &&
        form.value.language &&
        form.value.name
      );
    };

    const uploadMember = async () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      const formData = new FormData();
      formData.append("file", mainImage.value);
      formData.append("job", form.value.job);
      formData.append("language", form.value.language);
      formData.append("name", form.value.name);
      formData.append("text", editorData.value);

      try {
        let res;
        if (form.value.id) {
          formData.append("id", form.value.id);
          res = await clientInstance.post("/ourteam/update", formData);
        } else {
          res = await clientInstance.post("/ourteam/insert", formData);
        }

        if (res.status === 200) {
          isSubmitted.value = false;
          alert("Амжилттай шинэчлэгдлээ.");
          window.location.reload();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    const resetForm = () => {
      form.value = {
        id: null,
        job: null,
        language: 1,
        name: null,
      };
      imageSrc.value = defaultImage;
      mainImage.value = null;
      editorData.value = null;
    };

    const cancelEditMode = () => {
      resetForm();
      emit("cancel-edit");
    };

    /* CKEDITOR */
    class MyUploadAdapter {
      constructor(loader) {
        this.loader = loader;
      }

      upload() {
        return this.loader.file.then(
          (file) =>
            new Promise((resolve, reject) => {
              this._initRequest();
              this._initListeners(resolve, reject, file);
              this._sendRequest(file);
            })
        );
      }

      abort() {
        if (this.xhr) {
          this.xhr.abort();
        }
      }

      _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open("POST", "https://cce.mn/newsweb/ckupload", true);
        xhr.responseType = "json";
      }

      _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
          const response = xhr.response;

          if (!response || response.error) {
            return reject(
              response && response.error
                ? response.error.message
                : genericErrorText
            );
          }

          console.log(response);
          resolve({
            default: response.url,
          });
        });

        if (xhr.upload) {
          xhr.upload.addEventListener("progress", (evt) => {
            if (evt.lengthComputable) {
              loader.uploadTotal = evt.total;
              loader.uploaded = evt.loaded;
            }
          });
        }
      }

      _sendRequest(file) {
        const data = new FormData();
        data.append("file", file);
        this.xhr.send(data);
      }
    }

    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    }

    const editorConfig = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      removePlugins: ["MediaEmbedToolbar", "Markdown"],
      alignment: {
        options: ["left", "right", "center", "justify"],
      },
      language: "en",
      licenseKey: "",
    };

    return {
      imageSrc,
      mainImage,
      onImageChange,
      form,
      isSubmitted,
      uploadMember,
      Editor,
      editorConfig,
      editorData,
      cancelEditMode,
    };
  },
};
</script>

<template>
  <div class="flex justify-center text-[#212529]">
    <h1
      class="text-center text-xl sm:text-2xl lg:text-3xl font-thin pt-8 p-3 border-b border-gray-500"
    >
      {{ lang === "mn" ? "Амжилтын түүхүүд" : "Success stories" }}
    </h1>
  </div>

  <main class="flex gap-5 mt-5 mb-10 min-h-[60vh]">
    <section v-if="!isLoading && story" class="w-full">
      <img :src="story.image" alt="story-image" />
      <h1 class="font-light text-4xl my-5 text-center">{{ story.title }}</h1>
      <p v-html="story.text" class="text-sm"></p>
    </section>

    <section
      v-else
      class="flex justify-center items-center w-full min-h-[50vh]"
    >
      <BarLoading />
    </section>

    <SocialFrame />
  </main>
</template>

<script>
import SocialFrame from "@/containers/list/SocialFrame.vue";
import BarLoading from "@/components/UI/BarLoading.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import clientInstance from "@/lib/instance";
import { useRoute } from "vue-router";

export default {
  name: "ArchiveDetails",
  components: {
    SocialFrame,
    BarLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const route = useRoute();

    const isLoading = ref(false);
    const story = ref({});
    const fetchStory = async (id) => {
      try {
        isLoading.value = true;
        const res = await clientInstance.post("/storiesweb/storiesDetail", {
          storiesId: id,
        });
        if (res.status === 200) {
          story.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchStory(route.params.id || 1);
    });

    return { lang, story, isLoading };
  },
};
</script>

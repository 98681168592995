<template>
  <ListContainer
    :title="title"
    :data="beneficiaries"
    :details="false"
    :isLoading="isLoading"
  />
</template>

<script>
import clientInstance from "@/lib/instance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import ListContainer from "@/containers/list/ListContainer.vue";

export default {
  name: "BeneficiariesFeedback",
  components: {
    ListContainer,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const title = {
      mn: "Үр шим хүртэгчдийн сэтгэгдэл",
      en: "Feedback from Beneficiareies",
    };
    const beneficiaries = ref([]);
    const isLoading = ref(false);

    const fetchFeedback = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/feedBackFromBenefWeb/all");
        if (res.status === 200) {
          beneficiaries.value = res.data;
          console.log(res.data);
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchFeedback();
    });

    watch(lang, async () => {
      await fetchFeedback();
    });

    return {
      lang,
      title,
      beneficiaries,
      isLoading,
    };
  },
};
</script>

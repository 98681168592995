<template>
  <div class="flex justify-center text-[#212529]">
    <h1
      class="text-center text-xl sm:text-2xl lg:text-3xl font-thin pt-8 p-3 border-b border-gray-500"
    >
      {{ lang === "mn" ? "Удирдах зөвлөл" : "Board members" }}
    </h1>
  </div>

  <div
    v-if="!isLoading && boardMembers.length > 0"
    class="grid grid-cols-1 gap-5 text-[#212529] my-5 max-[1199px]:px-3"
  >
    <div
      v-for="member in boardMembers"
      :key="member.id"
      class="flex max-sm:flex-col items-center bg-white gap-5 sm:gap-10 shadow-lg rounded-lg p-5"
    >
      <img
        :src="member.image"
        alt="profile"
        class="w-40 sm:w-52 rounded-full object-fit"
      />

      <div class="block">
        <h2 class="text-lg sm:text-2xl">{{ member.name }}</h2>
        <h4 class="text-base sm:text-lg font-thin">{{ member.job }}</h4>
        <p v-html="member.text" class="pt-3 font-thin max-sm:text-sm"></p>
      </div>
    </div>
  </div>

  <div
    v-else-if="!isLoading && boardMembers.length === 0"
    class="flex justify-center items-center h-[70vh]"
  >
    {{
      lang === "mn"
        ? "Хуудас хоосон байна."
        : "No data available at the moment."
    }}
  </div>

  <div v-else class="flex justify-center items-center h-[70vh]">
    <BarLoading />
  </div>
</template>

<script>
import clientInstance from "@/lib/instance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import BarLoading from "@/components/UI/BarLoading.vue";

export default {
  name: "BoardMembers",
  components: {
    BarLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const boardMembers = ref([]);
    const fetchBoardMembers = async () => {
      try {
        isLoading.value = true;
        const language = lang.value === "mn" ? 1 : 2;
        const res = await clientInstance.post("/boardmember/allLanguage", {
          language: language,
        });
        if (res.status === 200) {
          boardMembers.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchBoardMembers();
    });

    watch(lang, async () => {
      await fetchBoardMembers();
    });

    return { lang, isLoading, boardMembers };
  },
};
</script>

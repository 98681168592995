<template>
  <AdminNavbar>
    <h1 class="text-center font-bold text-xl">Үр шим хүртэгчдийн сэтгэгдэл</h1>

    <main class="bg-white rounded-xl min-h-[80vh] p-5">
      <section class="border mb-10 p-3">
        <div class="flex items-center gap-5 mt-5">
          <label for="image" class="w-[10rem] text-end">
            Зураг:<span class="text-red-500">*</span>
          </label>
          <div class="space-y-3 w-full">
            <input
              id="image"
              name="image"
              type="file"
              class="w-full p-2 border rounded"
              :class="!mainImage && isSubmitted ? 'border-red-500' : ''"
              @change="onImageChange"
              accept="image/jpeg, image/png"
            />
          </div>
        </div>

        <div class="flex gap-5">
          <span class="w-[10rem]"></span>
          <div class="w-full">
            <span class="text-sky-500 text-xs">
              Зөвхөн jpg, jpeg, png өргөтгөлтэй зураг оруулна уу.
            </span>
            <div
              class="flex flex-col justify-center items-center w-full min-h-[30vh] mt-3"
            >
              <img
                :src="imageSrc"
                alt="image"
                :class="!mainImage ? 'w-14 h-14' : 'w-1/3 object-contain'"
              />
              <span v-if="!mainImage" class="text-xs text-gray-500"
                >Зураг байхгүй байна</span
              >
            </div>
          </div>
        </div>

        <div class="flex justify-center mt-5">
          <button
            @click="uploadReport()"
            class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2 bg-[#28a745] hover:bg-[#208537]"
          >
            <font-awesome-icon icon="fa-solid fa-plus" />
            Нэмэх
          </button>
        </div>
      </section>

      <section>
        <h1 class="text-center font-bold mb-3 text-lg">
          Үр шим хүртэгчдийн сэтгэгдлүүд
        </h1>

        <div v-if="feedback.length > 0 && !isLoading">
          <hot-table :settings="hotSettings" :data="feedback"></hot-table>
        </div>

        <div
          v-else-if="feedback.length === 0 && !isLoading"
          class="flex justify-center items-center h-[60vh]"
        >
          Жагсаалт хоосон байна.
        </div>

        <div v-else class="flex justify-center items-center h-[60vh]">
          <SpinLoading />
        </div>
      </section>
    </main>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/navigation/AdminNavbar.vue";
import SpinLoading from "@/components/UI/SpinLoading.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";
import { onMounted, ref } from "vue";
import clientInstance from "@/lib/instance";

registerAllModules();

export default {
  name: "AdminArchiveFeedback",
  components: {
    AdminNavbar,
    SpinLoading,
    HotTable,
  },
  setup() {
    const isLoading = ref(false);
    const feedback = ref([]);

    const fetchFeedback = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/feedBackFromBenefWeb/all");
        if (res.status === 200) feedback.value = res.data;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchFeedback();
    });

    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        imageSrc.value = defaultImage;
        mainImage.value = null;
      }
    };

    const imageRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<div class="relative flex items-center justify-center p-2">
                  <img src="${value}" alt="" class="w-1/2 object-fit" />
                </div>`;
    };

    const hotSettings = {
      colHeaders: ["Үйлдэл", "Зураг"],
      colWidths: [130, 400],
      columns: [
        {
          renderer: function (inst, td, row) {
            const currentReport = feedback.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                        <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                            <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                            <span>Устгах</span>
                        </button>
                    </div>`;

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteReport(currentReport.id);
              });
            }
          },
          readOnly: true,
        },
        {
          data: "image",
          readOnly: true,
          renderer: imageRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "70vh",
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const deleteReport = async (feedId) => {
      if (!window.confirm(`Сэтгэгдлийг устгах уу?`)) {
        return;
      }

      try {
        const res = await clientInstance.put("/feedBackFromBenefWeb/delete", {
          id: feedId,
        });
        if (res.status === 200) {
          await fetchFeedback();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    const isSubmitted = ref(false);
    const validateForm = () => {
      return mainImage.value;
    };

    const uploadReport = async () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      const formData = new FormData();
      formData.append("file", mainImage.value);

      try {
        const res = await clientInstance.post(
          "/feedBackFromBenefWeb/post",
          formData
        );
        if (res.status === 200) {
          isSubmitted.value = false;
          alert("Амжилттай шинэчлэгдлээ.");
          window.location.reload();
        } else {
          alert("Алдаа гарлаа.");
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err.response?.data?.message || err.message}`);
      }
    };

    return {
      isLoading,
      feedback,
      hotSettings,
      imageSrc,
      mainImage,
      onImageChange,
      isSubmitted,
      uploadReport,
    };
  },
};
</script>

<template>
  <div class="bg-white shadow">
    <img
      :src="data.image"
      alt="image"
      class="w-full h-52 md:h-44 xl:h-52 object-fit"
    />
    <div class="flex flex-col items-center gap-8 p-3">
      <h1 class="text-center line-clamp-2 font-thin">{{ data.title }}</h1>
      <h6 v-if="data.date" class="font-thin">{{ formattedDate }}</h6>
      <button
        @click="getNewsDetails(data.id)"
        class="bg-[#40a4c8] py-2 px-5 text-white"
      >
        Унших
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "DetailsCard",
  props: {
    data: {
      type: Object,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const getNewsDetails = (id) => {
      if (route.path.includes("/news")) {
        router.push(`/news/${id}`);
      } else if (route.path.includes("/archive")) {
        router.push(`/archive/${id}`);
      } else if (route.path.includes("/publication/civic-education")) {
        router.push(`/publication/civic-education/${id}`);
      } else if (route.path.includes("/publication/civic-participation")) {
        router.push(`/publication/civic-participation/${id}`);
      } else if (route.path.includes("/publication/youth-development")) {
        router.push(`/publication/youth-development/${id}`);
      } else if (route.path.includes("/publication/ecology-education")) {
        router.push(`/publication/ecology-education/${id}`);
      } else {
        router.push(`/news/${id}`);
      }
    };

    const formattedDate = computed(() => {
      return new Date(props.data.date).toISOString().split("T")[0];
    });

    return { getNewsDetails, formattedDate };
  },
};
</script>

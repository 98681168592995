<template>
  <main class="min-h-[70vh]">
    <div class="flex justify-center text-[#212529]">
      <h1
        class="text-center text-xl sm:text-2xl lg:text-3xl font-thin pt-8 p-3 border-b border-gray-500"
      >
        {{ lang === "mn" ? "Тайлан" : "Reports" }}
      </h1>
    </div>

    <div
      class="grid grid-cols-1 min-[450px]:grid-cols-2 sm:grid-cols-3 min-[800px]:grid-cols-4 gap-5 my-10 max-[1199px]:px-3"
    >
      <FileCard v-for="report in reports" :key="report.id" :item="report" />
    </div>
  </main>
</template>

<script>
import clientInstance from "@/lib/instance";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import FileCard from "@/containers/list/FileCard.vue";

export default {
  name: "ArchiveReports",
  components: {
    FileCard,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const reports = ref([]);

    const fetchReports = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/reportsWeb/all");
        if (res.status === 200) {
          reports.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchReports();
    });

    return { lang, isLoading, reports };
  },
};
</script>

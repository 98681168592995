<template>
  <main class="text-[#212529] font-light">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }"
      :navigation="true"
      :pagination="{ clickable: true }"
      class="customSwiper"
    >
      <swiper-slide
        v-for="slide in slideImages"
        :key="slide.id"
        class="relative"
      >
        <img :src="slide.image" alt="slider-image" class="w-full object-fit" />
        <div
          class="hidden min-[400px]:flex absolute bottom-8 justify-center w-full"
        >
          <div
            class="flex flex-col justify-center gap-2 w-2/3 h-[3rem] md:h-[8rem] bg-[#2c2b2c] bg-opacity-80 text-center text-white"
          >
            <p class="font-bold text-base sm:text-lg md:text-2xl lg:text-3xl">
              {{ slide.title }}
            </p>
            <p class="hidden md:block">{{ slide.text }}</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <section class="my-5 max-[1199px]:px-5">
      <h1 class="text-center text-3xl">
        {{ lang === "mn" ? "Мэдээ" : "News" }}
      </h1>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full gap-5 mt-5"
      >
        <DetailsCard v-for="item in news" :key="item.id" :data="item" />
      </div>
    </section>

    <section class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full">
      <div v-for="(edu, index) in education" :key="index" class="relative">
        <img :src="edu.image" alt="image" class="w-full h-60 object-cover" />
        <router-link
          :to="edu.to"
          class="absolute inset-0 flex justify-center items-center text-white text-xl"
        >
          <p class="w-2/3 text-center">{{ edu.title }}</p>
        </router-link>
      </div>
    </section>

    <section class="relative my-5">
      <img
        src="../assets/images/home-edu/homeIntro.png"
        alt="image"
        class="w-full object-cover"
      />
      <div
        class="absolute inset-0 flex flex-col justify-center items-center gap-3 lg:gap-10 text-white p-5"
      >
        <p class="text-center text-sm min-[800px]:text-base lg:text-lg">
          {{
            lang === "mn"
              ? "Иргэний Боловсролын төв (ИБТ) нь Монгол улсад ардчилсан иргэний нийгмийг төлөвшүүлэн хөгжүүлэх үйлсэд хувь нэмрээ оруулах зорилгоор байгуулагдсан ашгийн бус, нийгэмд үйлчилдэг төрийн бус байгууллага юм."
              : "CCE is a non-profit,community serving NGO with the purpose of contrubuting to the development of democratic civil society in Mongolia."
          }}
        </p>
        <p
          class="max-md:hidden text-center text-sm min-[800px]:text-base lg:text-lg border-t border-[#40a4c8] pt-5"
        >
          {{
            lang === "mn"
              ? "Анх 1992 онд Төв Азийн Хөгжлийн Сан нэртэй байгуулагдсан анхны төрийн бус байгууллагуудын нэг бөгөөд 1995 оноос Иргэний Боловсролын Төв хэмээн нэрээ өөрчилсөн. ИБТ нь иргэний боловсрол, иргэдийн оролцоо, төрийн бус байгууллагын менежмент, экологийн боловсрол зэрэг чиглэлээр сургалт семинар зохион байгуулах, сургалтын хөтөлбөр боловсруулах, ном, сурах бичиг, гарын авлага хэвлэн нийтлэх, мэргэжлийн зөвлөгөө өгөх, үндэсний болон олон улсын хурал, арга хэмжээ зохион байгуулах зэрэг үйл ажиллагаа явуулдаг."
              : "Founded in 1992, CCE,originally named Central Asia Development Foundation (CADF) was one of the oldest NGOs in Mongolia.Since 1995,it has been named the Centre of Citizenship Education (CCE),CCE organises workshops,training on civis education,citizens'participation, NGO management and ecological education;develops training curriculum;publishes textbooks, book and manuals;provides professional advice and organises national and international conferences and seminars."
          }}
        </p>
      </div>
    </section>

    <section>
      <h1 class="text-center text-3xl border-b border-gray-200 pb-3">
        {{ lang === "mn" ? "Видео" : "Video" }}
      </h1>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-between gap-1 my-5 max-[1199px]:px-3"
      >
        <iframe
          v-for="(video, index) in videos"
          :key="index"
          :src="video"
          frameborder="0"
          class="w-full"
        ></iframe>
      </div>
    </section>
  </main>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import DetailsCard from "@/containers/list/DetailsCard.vue";
import clientInstance from "../lib/instance";
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css/bundle";

export default {
  name: "HomePage",
  components: {
    DetailsCard,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const news = ref([]);
    const slideImages = ref([]);

    const fetchData = async () => {
      try {
        const res = await clientInstance.post("/newsweb/newslanguage", {
          language: 1,
        });
        if (res.status === 200) {
          news.value = res.data.slice(0, 4);
        }
        const slideRes = await clientInstance.get("/sliderweb/all");
        if (slideRes.status === 200) {
          slideImages.value = slideRes.data;
        }
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    const education = ref([
      {
        to: "/",
        title: "Иргэний боловсрол хөтөлбөр",
        image: require("../assets/images/home-edu/civicedu.png"),
      },
      {
        to: "/",
        title: "Иргэдийн оролцоо хөтөлбөр",
        image: require("../assets/images/home-edu/civicpar.png"),
      },
      {
        to: "/",
        title: "Залуучуудын хөтөлбөр",
        image: require("../assets/images/home-edu/youthdev.png"),
      },
      {
        to: "/",
        title: "Залуучуудын хөтөлбөр",
        image: require("../assets/images/home-edu/ecoedu.png"),
      },
    ]);

    const videos = ref([
      "https://www.youtube.com/embed/3gdPFH-1FIo",
      "https://www.youtube.com/embed/HjWMhflKgFk",
      "https://www.youtube.com/embed/nq0xodtXgy4",
      "https://www.youtube.com/embed/s2x0-Mf79dE",
    ]);

    return {
      lang,
      news,
      education,
      videos,
      slideImages,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>

<style scoped>
.customSwiper {
  position: relative;
  --swiper-pagination-color: #fff;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-inactive-color: #f3f4f6;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  --swiper-button-prev: #fff;
}

.customSwiper :deep(.swiper-button-next),
.customSwiper :deep(.swiper-button-prev) {
  color: #fff;
  width: 100px;
  height: 100px;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.customSwiper:hover :deep(.swiper-button-next),
.customSwiper:hover :deep(.swiper-button-prev) {
  display: flex;
}

.customSwiper :deep(.swiper-button-next::after),
.customSwiper :deep(.swiper-button-prev::after) {
  font-size: 30px;
}

.customSwiper :deep(.swiper-button-next:hover),
.customSwiper :deep(.swiper-button-prev:hover) {
  color: #6b7280;
  opacity: 100%;
}
</style>

<template>
  <section>
    <div class="p-3 border-b">
      <button
        @click="cancelEditMode()"
        class="p-2 text-gray-500 text-xs rounded border border-gray-300 hover:bg-gray-400 hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />Буцах
      </button>
    </div>

    <div class="p-5">
      <div class="flex items-center gap-5 mt-5">
        <label for="title" class="w-[10rem] text-end"
          >Гарчиг:<span class="text-red-500">*</span></label
        >
        <input
          id="title"
          name="title"
          type="text"
          placeholder="Нийтлэлийн гарчиг"
          class="w-full p-2 border rounded"
          :class="!form.title && isSubmitted ? 'border-red-500' : ''"
          v-model="form.title"
        />
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="uploadPublication()"
          class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2 hover:scale-105"
          :class="form.id ? 'bg-blue-500' : 'bg-[#28a745]'"
        >
          <font-awesome-icon
            :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-plus'"
          />
          {{ form.id ? "Засварыг хадгалах" : "Нийтлэл нэмэх" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "EditPublication",
  props: {
    publication: {
      type: Object,
    },
  },
  components: {},
  setup(props, { emit }) {
    const form = ref({
      id: null,
      title: null,
    });

    onMounted(() => {
      try {
        if (props.publication) {
          form.value.id = props.publication.id;
          form.value.title = props.publication.title;
        }
      } catch (err) {
        console.error(err);
      }
    });

    const resetForm = () => {
      form.value = {
        id: null,
        title: null,
      };
    };

    const cancelEditMode = () => {
      resetForm();
      emit("cancel-edit");
    };

    const isSubmitted = ref(false);
    const validateForm = () => {
      return form.value.title;
    };

    const uploadPublication = () => {
      isSubmitted.value = true;
      if (!validateForm()) return;

      try {
        emit("upload-pub", form.value);
      } catch (err) {
        return err;
      }
    };

    return {
      form,
      cancelEditMode,
      uploadPublication,
      isSubmitted,
    };
  },
};
</script>

<template>
  <!-- for small screen -->
  <header
    class="flex lg:hidden bg-white border-b border-[#40a4c8] text-[#212529]"
  >
    <div class="flex justify-between items-center w-full py-1 px-3">
      <router-link to="/" class="flex items-center">
        <img
          src="../../assets/images/logo/logo.png"
          alt="logo"
          class="w-12 object-contain"
        />
        <h1 class="uppercase text-sm md:text-lg font-light text-nowrap">
          {{
            lang === "mn"
              ? "Иргэний боловсролын төв"
              : "Center for citizenship education"
          }}
        </h1>
      </router-link>

      <button @click="toggleNavbar()">
        <font-awesome-icon icon="fa-solid fa-bars" />
      </button>
    </div>

    <transition name="fade">
      <main v-if="isNavbarOpen" class="w-full h-full">
        <div
          @click="toggleNavbar()"
          class="fixed inset-0 w-full h-full bg-black bg-opacity-50 z-[90]"
        ></div>

        <div
          class="fixed top-0 right-0 z-[100] h-screen w-[15rem] sm:w-[20rem] bg-white text-black text-sm p-5 overflow-y-scroll"
        >
          <button @click="toggleNavbar()" class="absolute top-3 right-3">
            <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
          </button>

          <ul class="mt-5">
            <li
              v-for="(link, index) in menu"
              :key="index"
              class="flex flex-col border-b border-slate-200 py-3"
              :class="
                link.to !== null || link.to !== '' ? 'hover:text-[#40a4c8]' : ''
              "
            >
              <router-link
                :to="link.to"
                @click="toggleNavbar()"
                class="w-full uppercase font-bold"
              >
                {{ lang === "mn" ? link.name : link.name_en }}
              </router-link>

              <ul v-if="link.children" class="ml-3 mt-2 space-y-1 list-disc">
                <li
                  v-for="child in link.children"
                  :key="child.cid"
                  class="text-wrap hover:text-[#40a4c8]"
                >
                  <router-link
                    :to="child.to"
                    @click="toggleNavbar()"
                    class="block"
                  >
                    {{ lang === "mn" ? child.name : child.name_en }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </main>
    </transition>
  </header>

  <!-- for large screen -->
  <div class="hidden lg:flex justify-center w-full">
    <header
      class="flex justify-between items-center bg-white w-full px-5 border-b border-[#40a4c8] text-[#212529]"
    >
      <router-link to="/" class="flex items-center xl:gap-3 py-2">
        <img
          src="../../assets/images/logo/logo.png"
          alt="logo"
          class="w-12 object-contain"
        />
        <h1 class="uppercase text-lg font-light">
          {{
            lang === "mn"
              ? "Иргэний боловсролын төв"
              : "Center for citizenship education"
          }}
        </h1>
      </router-link>

      <ul class="flex gap-5">
        <li
          v-for="(link, index) in menu"
          :key="index"
          class="group relative py-4 border-b-4 hover:border-[#40a4c8]"
          :class="
            isRoute === link.to ? 'border-[#40a4c8]' : 'border-transparent'
          "
        >
          <router-link
            :to="link.to"
            class="flex items-center gap-0.5 uppercase font-light"
          >
            {{ lang === "mn" ? link.name : link.name_en }}
            <font-awesome-icon
              v-if="link.children && link.children.length"
              icon="fa-solid fa-angle-down"
              class="text-xs"
            />
          </router-link>

          <ul
            v-if="link.children && link.children.length"
            class="invisible group-hover:visible absolute top-12 left-0 bg-white z-10 border text-nowrap"
          >
            <li
              v-for="(child, chIndex) in link.children"
              :key="chIndex"
              class="p-1 hover:bg-[#40a4c8] hover:text-white"
            >
              <router-link
                v-if="child.name !== 'Бичлэгүүд' || child.name_en !== 'Videos'"
                :to="child.to"
              >
                {{ lang === "mn" ? child.name : child.name_en }}
              </router-link>
              <a v-else :href="child.to" target="_blank">{{
                lang === "mn" ? child.name : child.name_en
              }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </header>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MainNavbar",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const route = useRoute();
    const isRoute = computed(() => route.path);

    const menu = ref([
      {
        name: "Нүүр",
        name_en: "Home",
        to: "/",
      },
      {
        name: "Бидний тухай",
        name_en: "About us",
        to: "",
        children: [
          {
            name: "Удирдах зөвлөл",
            name_en: "Board members",
            to: "/about/board-members",
          },
          {
            name: "Манай баг",
            name_en: "Our team",
            to: "/about/our-team",
          },
          {
            name: "Түүхэн товчоо",
            name_en: "Chronicles",
            to: "/about/chronicles",
          },
          {
            name: "Хамтрагч байгууллага",
            name_en: "Partners",
            to: "/about/partners",
          },
        ],
      },
      {
        name: "Хөтөлбөр",
        name_en: "Programs",
        to: "",
        children: [
          {
            name: "Иргэний боловсрол",
            name_en: "Civic education",
            to: "/programs/civic-education",
          },
          {
            name: "Иргэдийн оролцоо",
            name_en: "Civic Participation",
            to: "/programs/civic-participation",
          },
          {
            name: "Залуучуудын хөгжил",
            name_en: "Youth development project",
            to: "/programs/youth-development",
          },
          {
            name: "Экологийн боловсрол",
            name_en: "Ecological education",
            to: "/programs/ecology-education",
          },
          {
            name: "Иргэний боловсрол (Хэрэгжүүлсэн)",
            name_en: "Civic Education (Past)",
            to: "/programs/civic-education-past",
          },
          {
            name: "Иргэдийн оролцоо (Хэрэгжүүлсэн)",
            name_en: "Civic Participation (Past)",
            to: "/programs/civic-participation-past",
          },
          {
            name: "Залуучуудын хөгжил (Хэрэгжүүлсэн)",
            name_en: "Youth development project (Past)",
            to: "/programs/youth-development",
          },
          {
            name: "Экологийн боловсрол (Хэрэгжүүлсэн)",
            name_en: "Ecological education (Past)",
            to: "/programs/ecology-education",
          },
        ],
      },
      {
        name: "Мэдээ",
        name_en: "News",
        to: "/news",
      },
      {
        name: "Архив",
        name_en: "Archives",
        to: "",
        children: [
          {
            name: "Тайлан",
            name_en: "Reports",
            to: "/archive/reports",
          },
          {
            name: "Амжилтын түүхүүд",
            name_en: "Success stories",
            to: "/archive/stories",
          },
          {
            name: "Үр шим хүртэгчдийн сэтгэгдэл",
            name_en: "Feedback from beneficiaries",
            to: "/archive/beneficiary-feedback",
          },
          {
            name: "Хандивын үнэлгээ",
            name_en: "Donor's evaluation",
            to: "/archive/donor-evaluation",
          },
          {
            name: "Бичлэгүүд",
            name_en: "Videos",
            to: "https://www.youtube.com/channel/UCFNAs-2YPLcjiGpL43THIZw",
          },
        ],
      },
      {
        name: "Нийтлэлүүд",
        name_en: "Publications",
        to: "",
        children: [
          {
            name: "Иргэний боловсрол",
            name_en: "Civic Education",
            to: "/publication/civic-education",
          },
          {
            name: "Иргэдийн оролцоо",
            name_en: "Civic participation",
            to: "/publication/civic-participation",
          },
          {
            name: "Залуучуудын хөгжил",
            name_en: "Youth development project",
            to: "/publication/youth-development",
          },
          {
            name: "Экологийн боловсрол",
            name_en: "Ecological Education",
            to: "/publication/ecology-education",
          },
        ],
      },
    ]);

    const isNavbarOpen = ref(false);
    const toggleNavbar = () => {
      isNavbarOpen.value = !isNavbarOpen.value;
    };

    return { lang, menu, isRoute, isNavbarOpen, toggleNavbar };
  },
};
</script>

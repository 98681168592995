<template>
  <div class="flex items-center" :class="`text-${style.fontSize}`">
    <button
      @click="goToPage(1)"
      :disabled="currentPage === 1"
      class="flex justify-center items-center h-6 w-6 border border-gray-100 text-blue-500 text-sm hover:enabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
    >
      <font-awesome-icon icon="fa-solid fa-angle-double-left" />
    </button>
    <button
      @click="prevPage"
      :disabled="currentPage === 1"
      class="flex justify-center items-center h-6 w-6 border border-gray-100 text-blue-500 text-sm hover:enabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
    >
      <font-awesome-icon icon="fa-solid fa-angle-left" />
    </button>

    <button
      v-for="page in displayedPages"
      :key="page"
      @click="goToPage(page)"
      class="border border-gray-100"
      :class="
        currentPage === page
          ? `h-6 w-6 bg-${style.color} text-white`
          : `h-6 w-6 text-blue-500 hover:bg-gray-200`
      "
    >
      {{ page }}
    </button>

    <button
      @click="nextPage"
      :disabled="currentPage === totalPages"
      class="flex justify-center items-center h-6 w-6 border border-gray-100 text-blue-500 text-sm hover:enabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
    >
      <font-awesome-icon icon="fa-solid fa-angle-right" />
    </button>
    <button
      @click="goToPage(totalPages)"
      :disabled="currentPage === totalPages"
      class="flex justify-center items-center h-6 w-6 border border-gray-100 text-blue-500 text-sm hover:enabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
    >
      <font-awesome-icon icon="fa-solid fa-angle-double-right" />
    </button>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
    style: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentPage = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newVal) => {
        currentPage.value = newVal;
      }
    );

    const displayedPages = computed(() => {
      if (props.totalPages <= 3) {
        return Array.from({ length: props.totalPages }, (_, i) => i + 1);
      }

      if (currentPage.value === 1) {
        return [1, 2, 3];
      } else if (currentPage.value === props.totalPages) {
        return [props.totalPages - 2, props.totalPages - 1, props.totalPages];
      } else {
        return [
          currentPage.value - 1,
          currentPage.value,
          currentPage.value + 1,
        ];
      }
    });

    const goToPage = (page) => {
      currentPage.value = page;
      emit("page-changed", page);
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        goToPage(currentPage.value - 1);
      }
    };

    const nextPage = () => {
      if (currentPage.value < props.totalPages) {
        goToPage(currentPage.value + 1);
      }
    };

    return {
      currentPage,
      displayedPages,
      goToPage,
      prevPage,
      nextPage,
    };
  },
};
</script>

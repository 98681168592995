<template>
  <ListContainer
    :title="title"
    :data="stories"
    :details="true"
    :isLoading="isLoading"
  />
</template>

<script>
import clientInstance from "@/lib/instance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import ListContainer from "@/containers/list/ListContainer.vue";

export default {
  name: "SuccessStories",
  components: {
    ListContainer,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const title = {
      mn: "Амжилтын түүхүүд",
      en: "Success stories",
    };
    const stories = ref([]);
    const isLoading = ref(false);

    const fetchSuccessStories = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/storiesweb/allstories");
        if (res.status === 200) {
          stories.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchSuccessStories();
    });

    watch(lang, async () => {
      await fetchSuccessStories();
    });

    return {
      lang,
      title,
      stories,
      isLoading,
    };
  },
};
</script>

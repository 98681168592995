<template>
  <PublicationContainer
    :title="title"
    :data="education"
    :isLoading="isLoading"
  />
</template>

<script>
import PublicationContainer from "@/containers/publications/PublicationContainer.vue";
import clientInstance from "@/lib/instance";
import { onMounted, ref } from "vue";

export default {
  name: "CivicEducationPub",
  components: {
    PublicationContainer,
  },
  setup() {
    const title = {
      mn: "Иргэний боловсрол ном",
      en: "Civic Education Publication",
      color: "#ff0000",
    };
    const isLoading = ref(false);
    const education = ref([]);

    const fetchCivicEducationPub = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          "/publicationceWeb/publicationceall"
        );
        if (res.status === 200) {
          education.value = res.data;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchCivicEducationPub();
    });

    return {
      title,
      isLoading,
      education,
    };
  },
};
</script>

<template>
  <a
    v-if="item.file"
    :href="item.file"
    target="_blank"
    class="flex justify-center items-center bg-white hover:scale-105"
  >
    <img :src="item.image" alt="object-contain" />
  </a>

  <img v-else :src="item.image" alt="image" />
</template>

<script>
export default {
  name: "FileCard",
  props: {
    item: {
      type: Object,
    },
  },
};
</script>
